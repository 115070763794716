import React, {MouseEvent, useCallback, useRef, useState} from "react";
import { CSSTransition } from "react-transition-group";
import { UserPublicData } from "@teacherskaya/api-provider/dist/api/UserPublicDataProvider";
import integrations from "./types";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import BaseAvatar from "../BaseAvatar";
import "./style.scss";

export interface NotificationData {
  text: string | JSX.Element;
  timeout?: number;
  image?: {
    user?: UserPublicData | null;
    icon?: string;
  };
  onClick?: () => void;
}

export type NotificationFactory = (notification: NotificationData) => void;

export default function PushManager() {
  const [notification, setNotification] = useState<NotificationData | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  const clearNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const sendNotification = useCallback((notification: NotificationData) => {
    clearNotification();
    Promise.resolve().then(() => {
      setNotification(notification);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(clearNotification, notification.timeout || 5000);
    });
  }, [clearNotification]);

  const onCloseButtonHandler = (event: MouseEvent) => {
    event.stopPropagation();
    clearNotification();
  }

  return (
    <>
      <CSSTransition
        in={!!notification}
        nodeRef={nodeRef}
        unmountOnExit
        timeout={{ enter: 200, exit: 100 }}
        classNames="slide-right"
      >
        <div
          className="notification"
          ref={nodeRef}
          onClick={(event) => {
            if (notification?.onClick) {
              notification?.onClick();
              onCloseButtonHandler(event);
            }
          }}
        >
          {(notification?.image?.user?.avatar &&
            <BaseAvatar
              className="notification__avatar"
              size={24}
              user={notification.image.user}
            />
            ) || (
            notification?.image?.icon &&
            <BaseIcon className="notification__icon" size={24}>
              {notification?.image?.icon}
            </BaseIcon>
          )}
          <div className="notification__wrapper">
            <span className="notification__text">{notification?.text}</span>
          </div>
          <BaseButton
            className="notification__close"
            opacity={true}
            onClick={onCloseButtonHandler}
            icon={true}
          >
            <BaseIcon size={18}>close</BaseIcon>
          </BaseButton>
        </div>
      </CSSTransition>
      {integrations.map((Subscriber) => (
        <Subscriber.component key={Subscriber.key} sendNotification={sendNotification} />
      ))}
    </>
  );
}
