import React from "react";
import {I18n, useTranslation} from "../../locales";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import BaseButton from "../../components/BaseButton";
import ContentHeader from "../../components/ContentHeader";
import BaseForm from "../../components/BaseForm";
import BaseIcon from "../../components/BaseIcon";
import SocialLogin from "../../components/SocialLogin";
import { useViewController } from "./viewController";
import "./style.scss";
import config from "../../config";

interface Props {
  onClose: () => void;
}

export default function ClientSignUp({ onClose }: Props) {
  const { fields, values, errors, onJoin, onChange, setErrors } = useViewController();
  const { t } = useTranslation();
  const { getLocation, notification } = useModalLinkPrinter();

  const fieldsToPrint = fields.map((f) => {
    if (f.type === "checkbox" && f.name === "terms") {
      f.label = (
        <span className="client-signup__checkbox-label">
          <I18n
            path="Я даю согласие на обработку своих персональных данных в соответствии с <terms>Политикой конфиденциальности</terms>"
            components={{
              terms: (
                <a
                  className="link"
                  href={config.s3Url + "/policies/privacy_policy.pdf"}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  terms
                </a>
              ),
            }}
          />
        </span>
      );
    }
    return f;
  });

  return (
    <>
      <BaseForm
        className="client-signup"
        fields={fieldsToPrint}
        values={values}
        notification={notification}
        onChange={onChange}
        errors={errors}
        setErrors={setErrors}
        headline={<ContentHeader>{t("Регистрация")}</ContentHeader>}
        onSubmitEnter={onJoin}
      >
        <div className="client-signup__control-wrapper __buttons">
          <BaseButton name="submit" large={true} fluid={true} onClick={() => onJoin()}>
            {t("Зарегистрироваться")}
          </BaseButton>
        </div>
        <SocialLogin className="client-signup__social" onClose={onClose} title={t("или зарегистрироваться через")} />
        <div className="client-signup__control-wrapper __switch">
          <span className="caption">{t("Уже есть аккаунт?")}</span>
          <BaseButton
            opacity={true}
            to={getLocation("/login", { notification })}
            className="client-signup__switch-button fw-normal"
          >
            <BaseIcon className="mr-1">signin</BaseIcon>
            <span>{t("Войти")}</span>
          </BaseButton>
        </div>
      </BaseForm>
    </>
  );
}
