import { useEffect, useState } from "react";

export default function useController() {
  const [state, setState] = useState({ token: "", showModal: false, start: true, success: false });

  const onResetStart = ({ status }: { status: boolean }) => {
    setState((s) => ({ ...s, start: !status }));
  };

  const onCodeVerified = ({ token }: { token: string }) => {
    setState((s) => ({ ...s, token }));
  };

  const onSuccess = () => {
    setState((s) => ({ ...s, success: true }));
  };

  const onStart = () => {
    setState((s) => ({ ...s, showModal: true, success: false }));
  };

  const onClose = () => {
    setState((s) => ({ ...s, start: true, showModal: false, success: false, token: "" }));
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (state.success) {
      timeout = setTimeout(() => {
        setState({ token: "", showModal: false, start: true, success: false });
      }, 3000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [state]);

  return {
    ...state,
    onClose,
    onStart,
    onResetStart,
    onCodeVerified,
    onSuccess,
  };
}
