import React from "react";
import BaseTabs from "../../../../components/BaseTabs";
import NavigationMenuMobile from "../NavigationMenuMobile";
import { NavigationMenuItemInterface } from "../../../../types";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  itemsList: NavigationMenuItemInterface[];
}

const NavigationTabs = ({ itemsList }: Props) => {
  const { items } = useViewController(itemsList);
  return (
    <div className="navigation-tabs-menu">
      <BaseTabs itemList={items} className={"navigation-tabs__horizontal-menu"} />
      <NavigationMenuMobile
        className="navigation-tabs__horizontal-menu-mobile"
        outline={true}
        withoutIcons={true}
        itemsList={itemsList}
      />
    </div>
  );
};

export default NavigationTabs;
