import { InputUserUpdate } from "@teacherskaya/api-provider/dist/api/UserProvider";
import { FieldInterface, FormValues } from "../../types";
import { validateFieldsWithException } from "../../lib/validation";
import { getLoginData, setLoginData } from "../../lib/authHelper";
import useAuthUser from "../../hooks/useAuthUser";
import providers from "../../lib/providers";

function useController() {
  const { user } = useAuthUser();
  const loginData = getLoginData();

  async function onSaveFields(fields: FieldInterface[], values: FormValues) {
    validateFieldsWithException(fields, values);

    const data: InputUserUpdate = {
      id: user?.id || "",
      screenName: values.screenName,
    };
    const userResponse = await providers.UserProvider.update({ data });
    setLoginData({ user: userResponse, authorities: loginData?.authorities || null });
  }

  return { onSaveFields };
}

export default useController;
