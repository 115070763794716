import React, {useState} from "react";
import GameOptionSelection, {GameOption} from "./components/GameOptionSelection";
import GameImageSelection from "./components/GameImageSelection";

export interface ImageCard {
  id: number;
  category: string;
  name: string;
  urlPart: string;
  src: string;
  thumbnailSrc: string;
  bySubscription: boolean;
  selected: boolean;
}

interface Props {
  selectedImageNumbers?: GameOption;
  gameOptionsTitle?: string;
  gameOptions?: GameOption[];
  handleImageSelection: (images: ImageCard[]) => void;
}

export default function GameWithImageSettings({ selectedImageNumbers,
                                                gameOptionsTitle,
                                                gameOptions,
                                                handleImageSelection }: Props) {
  const [imageNumber, setImageNumber] = useState<GameOption | undefined>(selectedImageNumbers);

  return (
    <div className="game-image-selection">
      {(imageNumber || !gameOptions) ?
        <GameImageSelection needSelected={imageNumber?.optionValue} handleImageSelection={handleImageSelection}/>
        : <GameOptionSelection title={gameOptionsTitle} options={gameOptions || []} handleOption={setImageNumber}/>}
    </div>
  )
}