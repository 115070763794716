import {useTranslation} from "../../locales";
import paymentSystems from "../../assets/payment-systems.png";
import providers from "../../lib/providers";
import {InputVTBRecurringPurchase} from "@teacherskaya/api-provider/dist/api/VTBPurchaseProvider";
import {
  RecurringBillingScheduleSubjectTypeEnum
} from "@teacherskaya/api-provider/dist/api/RecurringBillingScheduleProvider";
import {useState} from "react";

export default function useController() {
  const { t } = useTranslation();
  const [loadingPurchaseButton, setLoadingPurchaseButton] = useState(false);

  const getSubscriptionLevel = async () => {
    const levels = await providers.SubscriptionLevelProvider.find({userIds: ["0"]});
    return levels![0];
  }

  const getPaymentSystem = async () => {
    const paymentSystems = await providers.PaymentSystemsProvider.findAllPublic()
    return paymentSystems![0];
  }

  const getPurchaseUrl = async (levelId: string, paymentSystemId: string) => {
    const data: InputVTBRecurringPurchase = {
      currency: "RUB",
      paymentSystemId: paymentSystemId,
      subject: { subjectId: levelId, subjectType: RecurringBillingScheduleSubjectTypeEnum.SUBSCRIPTION }
    };
    return  await providers.VTBPurchaseProvider.getRecurringUrl({ data });
  };

  const onClickPurchase = async () => {
    setLoadingPurchaseButton(true);
    const level = await getSubscriptionLevel();
    const paymentSystem = await getPaymentSystem();

    const purchaseUrl = await getPurchaseUrl(level.id, paymentSystem.id);
    window.open(purchaseUrl, "_self");
    setLoadingPurchaseButton(false);
  }

    const faqList = [
      {
        question: t("Как получить пробный доступ?"),
        answer: t(
          "В нашей группе вконтакте вы можете найти промокод на один бесплатный месяц"
        ),
      },
      {
        question: t("Как оплатить подписку?"),
        answer: t(
          "Для оплаты подписки нажмите кнопку \"Купить\" на текущей странице. "
          + "Оплата происходит через ВТБ (ПАО) с использованием банковских карт VISA, MasterCard, МИР"
        ),
        image: paymentSystems,
      },
      {
        question: t("На какой срок приобретается подписка?"),
        answer: t(
          "Подписка будет действовать 1 месяц, автоматического продления нет"
        ),
      },
      {
        question: t("Как продлить подписку?"),
        answer: t(
          "После окончания срока действия приобретенной подписки появится возможность купить подписку вновь"
        ),
      },
      {
        question: t("Безопасны ли платежи на сайте?"),
        answer: t(
          "Услуга оплаты через интернет осуществляется в соответствии с Правилами МПС Visa, MasterCard " +
          "и Платежной системы МИР на принципах соблюдения конфиденциальности и " +
          "безопасности совершения платежа, для чего используются самые современные методы " +
          "проверки, шифрования и передачи данных по закрытым каналам связи.\n" +
          "Настоящий сайт поддерживает 256-битное шифрование. Соединение с платёжным " +
          "шлюзом и передача информации осуществляется в защищённом режиме с использованием " +
          "протокола шифрования SSL. Введённая информация не будет предоставлена третьим " +
          "лицам за исключением случаев, предусмотренных законодательством РФ. Проведение " +
          "платежей по банковским картам осуществляется в строгом соответствии с требованиями " +
          "платёжных систем МИР, Visa Int., MasterCard Europe Sprl."
        ),
      },
      {
        question: t("У меня есть вопрос, куда можно обратиться?"),
        answer: "Напишите нам на почту support@teacherskaya.ru или в группу вконтакте"
      },
    ];

  return {
    faqList,
    onClickPurchase,
    loadingPurchaseButton
  };
}
