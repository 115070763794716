import React from "react";
import GameWithImageSettings, {ImageCard} from "../../../../GameSettings";
import {GameOption} from "../../../../GameSettings/components/GameOptionSelection";
import {LessonGameSettings} from "../../../../../types";

interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

interface DobbleOptions extends GameOption {
  imageOnCardNumber?: number;
}

const dobbleNumbers: DobbleOptions[] = [
  {
    optionValue: 13,
    optionText: "на одной карточке 4 картинки",
    bySubscription: false,
    imageOnCardNumber: 4,
  },
  {
    optionValue: 31,
    optionText: "на одной карточке 6 картинок",
    bySubscription: true,
    imageOnCardNumber: 6,
  },
  {
    optionValue: 57,
    optionText: "на одной карточке 8 картинок",
    bySubscription: true,
    imageOnCardNumber: 8,
  },
];

export default function DobbleGameSettings({ onGameConfigured }: Props) {
    const handleImageSelection = (images: ImageCard[]) => {
      const game: LessonGameSettings = {
        type: "DOBBLE",
        imageOnCardCount: dobbleNumbers.filter(d => d.optionValue === images.length)[0].imageOnCardNumber || 13,
        imageCount: images.length,
        images: images,
      }
      onGameConfigured(game);
    }
    return (
      <GameWithImageSettings gameOptions={dobbleNumbers} handleImageSelection={handleImageSelection}/>
    );
}
