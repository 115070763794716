import React from "react";
import "./style.scss";

interface Props {
  className?: string;
  size?: number | string;
  id?: string;
}

function BaseSpinner({ className, size, id = Math.random().toString(36).substring(2, 15) }: Props) {
  const cls = ["base-spinner", "__infinite"];
  if (className) cls.push(className);
  return (
    <div className={cls.join(" ")} style={{ width: size, height: size }}>
      <svg
        className="base-spinner__image"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        preserveAspectRatio="xMidYMid"
      >
        <defs>
          <linearGradient id={`gradient-${id}`} x1="0%" y1="15%" x2="0%" y2="100%">
            <stop className="base-spinner__gradient-start" offset="0%" />
            <stop className="base-spinner__gradient-end" offset="100%" />
          </linearGradient>
        </defs>
        <circle
          cx="16"
          cy="16"
          fill="none"
          stroke={`url(#gradient-${id})`}
          strokeWidth="2"
          r="15"
          strokeDasharray={2 * Math.PI * 15 * 0.75}
          strokeDashoffset={(2 * Math.PI * 15 * (100 - 120)) / 100}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export default BaseSpinner;
