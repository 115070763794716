import React from "react";
import "./style.scss";
import LottoCardImage, {LottoCardItem} from "../LottoCardImage";


interface Props {
    images: LottoCardItem[];
    color: string;
}

export default function LottoCard({ images, color }: Props) {
  return (
    <div className="lotto-card" style={{
      gridTemplateColumns: "1fr ".repeat(images.length >= 12 ? 4 : 3),
      maxWidth: images.length / 3 === 3 ? "330px" : "500px"
    }}>
      {images.map(i => (
        <LottoCardImage
          key={i.id}
          card={i}
          color={color}
        />
      ))}
    </div>);
}
