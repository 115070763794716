import React from "react";
// import aboutSrc from "../../../../assets/landing/about.png";
import {useTranslation} from "../../../../locales";
import BaseButton from "../../../../components/BaseButton";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";
import useModalLinkPrinter from "../../../../hooks/useModalLinkPrinter";
import config from "../../../../config";

export default function BlockAbout() {
  const { t } = useTranslation();
  const { getLocation } = useModalLinkPrinter();

  return (
    <div className="block-about">
      <img className="block-about__img" src={config.assetsUrl + "/about.png"} alt="aboutImg" />
      <div className="block-about__content">
        <h1 className="block-about__title">{t("Создавайте игры для ваших уроков")}</h1>
        <div className="block-about__description fw-normal">
          {t(
            "Используйте наши конструкторы и наборы картинок, чтобы быстро создавать игры для изучения материала на уроках английского."
          )}
        </div>
        <BaseButton className="block-about__btn" to={"/games"} large>
          {t("Создать игру")}
          <BaseIcon className="block-about__btn-icon ml-1">back</BaseIcon>
        </BaseButton>
      </div>
    </div>
  );
}
