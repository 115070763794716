import React, {useEffect, useState} from "react";
import "./style.scss";
import VocabularyIntroductionImage from "../VocabularyIntroductionImage";
import {ImageCard} from "../../../../GameSettings";
import BaseButton from "../../../../../components/BaseButton";
import BaseIcon from "../../../../../components/BaseIcon";
import useCacheImagesController from "../../../../../lib/cacheImages";
import BaseSpinner from "../../../../../components/BaseSpinner";
import config from "../../../../../config";


interface Props {
    images: ImageCard[];
}

export default function VocabularyIntroductionGamePlay({ images }: Props) {
    const [currentImageNumber, setCurrentImageNumber] = useState<number>(0);
    const [img, setImages] = useState<ImageCard[]>(images);
    const {loading, setLoading, cacheImages, cacheAssets} = useCacheImagesController();
    const frames = [
        config.assetsUrl + "/vocabulary-introduction-frame-1.png",
        config.assetsUrl + "/vocabulary-introduction-frame-3.png",
        config.assetsUrl + "/vocabulary-introduction-frame-2.png",
        config.assetsUrl + "/vocabulary-introduction-frame-4.png",
    ];

    useEffect(() => {
        setImages(prev => prev.sort(() => Math.random() - 0.5));
        setLoading(true);
        cacheAssets(frames);
        cacheImages([img[currentImageNumber]], true);
        cacheImages(img);
    }, []);

    const nextImage = () => {
        if(images.length > currentImageNumber) {
            setCurrentImageNumber(prev => prev + 1);
        }
    }

    const prevImage = () => {
        if(currentImageNumber > 0) {
            setCurrentImageNumber(prev => prev - 1);
        }
    }

    return (
        <div>
            {loading ? (
                <div className="vocabulary-introduction-game__spinner-container">
                    <BaseSpinner size={45} />
                </div>) :
            <div>
            <div className="vocabulary-introduction-game__card">
                <VocabularyIntroductionImage
                  frame={frames[currentImageNumber % 4]}
                  key={img[currentImageNumber].name}
                  image={img[currentImageNumber]}/>
            </div>
                <div className="vocabulary-introduction-game__game-buttons">
                    <BaseButton
                        className="vocabulary-introduction-game__back-button mr-2"
                        disabled={currentImageNumber <= 0}
                        round
                        large={true}
                        onClick={prevImage}
                    >
                        <BaseIcon>back</BaseIcon>
                    </BaseButton>
                    <BaseButton
                        className="vocabulary-introduction-game__next-button mr-2"
                        disabled={currentImageNumber >= img.length - 1}
                        round
                        large={true}
                        onClick={nextImage}
                    >
                        <BaseIcon className="vocabulary-introduction-game__next-icon-button">back</BaseIcon>
                    </BaseButton>
                </div>
                <div className="vocabulary-introduction-game__combinations">
                    <span>{(currentImageNumber + 1) + " / " + images.length}</span>
                </div>
            </div>}
        </div>
    );
}
