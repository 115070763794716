import React, {useEffect, useState} from "react";
import {useTranslation} from "../../locales";
import useAuthUser from "../../hooks/useAuthUser";
import BaseCard from "../../components/BaseCard";
import BaseButton from "../../components/BaseButton";
import "./styles.scss";
import PromoCodeActivation from "../../modals/PromoCodeActivation";
import {getDate} from "../../lib/timeHelper";
import BaseIcon from "../../components/BaseIcon";
import useController from "./controller";
import SubscriptionFaqItem from "./componets/FAQItem";
import {useLocation} from "react-router-dom";
import ActionStatus from "../../components/ActionStatus";
import ModalWindow from "../../components/ModalWindow";

export default function PageSubscription() {
  const { t } = useTranslation();
  const { user, hasSubscription } = useAuthUser();
  const [showPromoCodeActivationModal, setShowPromoCodeActivationModal] = useState(false);
  const [showSuccessSubscriptionModal, setShowSuccessSubscriptionModal] = useState(false);
  const { faqList, loadingPurchaseButton, onClickPurchase } = useController();
  const cls = "page-subscription";

  const orderId = new URLSearchParams(useLocation().search).get("orderId");
  useEffect(() =>{
    if (orderId) {
      setShowSuccessSubscriptionModal(true);
    }
  }, []);

  const onPromoCodeActivationStart = () => setShowPromoCodeActivationModal(true);
  const onPromoCodeActivationClose = () => setShowPromoCodeActivationModal(false);

  const expirationTime = (user && user.subscription) ? getDate(user!.subscription!.expirationTime) : undefined;

  const listText = [
    "Доступны все опции настроек игр",
    "Можно выбирать картинки из любых категорий",
    "Нет ограничений по количеству созданных планов"
  ]

  return (
    <>
      <BaseCard>
        <div className={cls}>

          {hasSubscription &&
            <>
              <BaseCard className={cls + "__actual-status"}>
                <div className={cls + "__active"}>
                  <div className={cls + "__active-title"}>{t("Подписка активна")}</div>
                  <div className={cls + "__active-time"}>
                    {t("действует до ")}
                    <span>{expirationTime!.dayOfMonth}.{expirationTime!.month}.{expirationTime!.year}</span>
                  </div>
                </div>
              </BaseCard>
            </>
          }

          {!hasSubscription &&
            <div className={cls + "__offer"}>
              <h1>Полный доступ ко всей платформе</h1>
              <div className={cls + "__offer-description-container"}>
                {listText.map((text, i) =>
                  <div key={i} className={cls + "__offer-description-item"}>
                    <BaseIcon className={cls + "__offer-description-icon"}>{"favorites"}</BaseIcon>
                    <p>{text}</p>
                  </div>
                )}
              </div>
              <BaseButton className={cls + "__offer-button"} loading={loadingPurchaseButton} large={true} round={true} onClick={onClickPurchase}>
                {t("Купить 1 месяц за 199 руб.")}
              </BaseButton>
            </div>
          }

          {/*{!hasSubscription &&*/}
          {/*  <div className={cls + "__tariffs"}>*/}
          {/*    <h1>Выберите вариант подписки</h1>*/}
          {/*    <BaseCard className={cls + "__tariff"}>*/}
          {/*      <h2 className={cls + "__tariff-title"}>1 месяц</h2>*/}
          {/*      <h1 className={cls + "__tariff-price"}>199 ₽</h1>*/}
          {/*      <BaseButton round={true} onClick={onPromoCodeActivationStart}>Купить</BaseButton>*/}
          {/*    </BaseCard>*/}
          {/*  </div>*/}
          {/*}*/}

          {!hasSubscription &&
            <div className={cls + "__promocode_offer"}>
              <div className={cls + "__promocode_offer-text-wrapper"}>
              <div className={cls + "__promocode_offer-title"}>Получите один бесплатный месяц с помощью промокода!</div>
                <div className={cls + "__promocode_offer-text"}>
                  Вы можете его найти <a target="_blank" href="https://vk.com/teacherskaya_platform">в нашей группе</a> в блоке "Получить&nbsp;промокод"
                </div>
              </div>
                <BaseButton className={cls + "__promocode_offer-button"} live={true} onClick={onPromoCodeActivationStart}>
                  Ввести промокод
                </BaseButton>
            </div>
          }

          <div className={cls + "__faq"}>
            <div className={cls + "__faq-title"}>Часто задаваемые вопросы</div>
            {faqList.map((el, i) => (
              <SubscriptionFaqItem className={"mb-2"} key={i} item={el} />
            ))}
          </div>
        </div>
      </BaseCard>
      <PromoCodeActivation show={showPromoCodeActivationModal} onClose={onPromoCodeActivationClose}/>
      <ModalWindow show={showSuccessSubscriptionModal} onClose={() => {setShowSuccessSubscriptionModal(false)}} className={cls + "__success-subscription-modal"}>
        <ActionStatus title={t("Спасибо за оплату!")}
                      description={t("Подписка активируется в ближайшее время")}
                      status={"success"}>
          <div className={cls + "__success-subscription-modal__buttons mt-6"}>
            <BaseButton className="notification-window__button" large={true} onClick={() => {setShowSuccessSubscriptionModal(false)}}>
              {t("Закрыть")}
            </BaseButton>
          </div>
        </ActionStatus>
      </ModalWindow>
    </>
  );
}
