import { validateFieldsWithException } from "../../lib/validation";
import { parseErrors } from "../../lib/requestHelpers";
import useBaseForm from "../../hooks/useBaseForm";
import { useTranslation } from "../../locales";
import { FormValues } from "../../types";
import { useController } from "./controller";

export function useViewController() {
  const { t } = useTranslation();

  const { fields, values, errors, setErrors, setValues } = useBaseForm([
    {
      name: "email",
      type: "email",
      placeholder: t("Email"),
      validation: {
        required: true,
      },
      autocomplete: "email",
      textAlign: "center",
    },
    {
      name: "password",
      type: "password",
      placeholder: t("Пароль"),
      validation: {
        required: true,
      },
      autocomplete: "current-password",
      textAlign: "center",
    },
  ]);
  const { onLogin } = useController();
  const onChange = (values: FormValues) => setValues(values);

  const sendLogin = async (cb?: () => void) => {
    try {
      validateFieldsWithException(fields, values);
      const data = {
        email: values.email,
        password: values.password,
      };
      await onLogin(data);
      cb && cb();
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  return {
    fields,
    values,
    errors,
    setErrors,
    onChange,
    onLogin: sendLogin,
  };
}
