import useAuthUser from "../../hooks/useAuthUser";

const useController = () => {
  const { user } = useAuthUser();

  return {
    user,
  };
};

export default useController;
