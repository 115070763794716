import { useState } from "react";
import { FieldInterface, FormValues } from "../types";

export default function useBaseForm(fields: FieldInterface[], initialValue: Record<string, any> = {}) {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [values, setValues] = useState<FormValues>(initialValue);
  return {
    fields,
    values,
    errors,
    setValues,
    setErrors,
  };
}
