import { NavigationMenuItemInterface } from "../../../../types";

const useViewController = (itemsList: NavigationMenuItemInterface[]) => {
  const items = itemsList.map((item: NavigationMenuItemInterface) => {
    return {
      ...item,
      className: `navigation-tabs__horizontal-menu-link ${item.className ? item.className : ""}`,
    };
  });

  return { items };
};

export default useViewController;
