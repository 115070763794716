import React from "react";
import BaseCard from "../../../../components/BaseCard";
import "./style.scss";
import GameImage from "../GameImage";
import {Game} from "../../../../types";

interface Props {
  game: Game;
  onGameTypeSelected: (type: Game) => void;
  selectedByPath?: boolean;
}

function GameCard({ game, onGameTypeSelected, selectedByPath }: Props) {
  const toGame = {
    pathname: game.path,
    state: { fromGames: true }
  }

  return (
    <BaseCard className={"game-card" + (selectedByPath ? " __selected" : "")}>
        <div className="game-card__inner" onClick={() => onGameTypeSelected(game)}>
          <GameImage
            to={toGame}
            game={game}
            className="game-card__game-image-container"
            classNameGameImage="game-card__game-image"
          />
          <div className="game-card__container">
            <div className="game-card__description">
              <h3 className="game-card__name">{game.name}</h3>
              <p className="game-card__about">{game.description}</p>
            </div>
          </div>
        </div>
    </BaseCard>
  );
}

export default GameCard;
