import React from "react";
import {useViewController} from "./viewController";
import "./style.scss";
import {useTranslation} from "../../../../locales";
import useModalLinkPrinter from "../../../../hooks/useModalLinkPrinter";
import BaseForm from "../../../../components/BaseForm";
import ContentHeader from "../../../../components/ContentHeader";
import BaseButton from "../../../../components/BaseButton";

interface Props {
  planId: string;
  onUpdated: (newName: string) => void;
}

function LessonPlanUpdate({ planId, onUpdated }: Props) {
  const { fields, values, errors, onUpdate, onChange, setErrors } = useViewController();
  const { t } = useTranslation();
  const { notification } = useModalLinkPrinter();

  return (
    <>
      <BaseForm
        className="lesson-plan-update"
        autofocus={true}
        fields={fields}
        values={values}
        errors={errors}
        notification={notification}
        onChange={onChange}
        setErrors={setErrors}
        headline={<ContentHeader>{t("Переименование плана")}</ContentHeader>}
        onSubmitEnter={() => onUpdate(planId, onUpdated)}
      >
        <>
          <div className="lesson-plan-update__control-wrapper __buttons">
            <BaseButton className="lesson-plan-update__create" name="submit" large={true} fluid={true} onClick={() => onUpdate(planId, onUpdated)}>
              {t("Переименовать")}
            </BaseButton>
          </div>
        </>
      </BaseForm>
    </>
  );
}

export default LessonPlanUpdate;
