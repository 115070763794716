import providers from "../../../../lib/providers";
import {getLoginData} from "../../../../lib/authHelper";

export function useController() {
  const loginData = getLoginData();

  async function onCreate(name: string) {
    const data = { userId: loginData.user?.id ?? "", name };
    return await providers.LessonPlanProvider.create({data});
  }

  return {
    onCreate,
  };
}
