import {GameImage} from "@teacherskaya/api-provider/dist/api/GameImageProvider";
import {useEffect, useState} from "react";
import providers from "../../../../lib/providers";

const useController = () => {

  const [state, setState] = useState<GameImage[] | null>(null);
  const [loading, setLoading] = useState(false);

  const getImages = async () => {
    const response = await providers.GameImageProvider.findPublic();
    setState(response);
  };

  useEffect(() => {
    setLoading(true);
    getImages().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    backImages: state,
    loading,
  };
};

export default useController;
