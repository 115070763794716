import React, {useCallback} from "react";
import BasePage from "../../components/BasePage";
import "./style.scss";
import {Redirect, useHistory} from "react-router-dom";
import useController from "./controller";
import BaseButton from "../../components/BaseButton";
import ModalWindow from "../../components/ModalWindow";
import BaseSpinner from "../../components/BaseSpinner";
import LessonGameUpdate from "./components/LessonGameUpdate";
import LessonGameDelete from "./components/LessonGameDelete";
import BaseIcon from "../../components/BaseIcon";
import {LocationState} from "../../types";
import ContentHeader from "../../components/ContentHeader";
import LessonGameItem from "./components/LessonGameItem";

interface Props {
  planId: string;
}

function PageLessonGames({ planId }: Props) {
  const {
    user,
    loading,
    plan,
    games,
    onCreateStart,
    showGameUpdateModal,
    onUpdateStart,
    onUpdateClose,
    onUpdated,
    showGameDeleteModal,
    onDeleteStart,
    onDeleted,
    onDeleteClose,
  } = useController(planId);
  const history = useHistory<LocationState>();
  const onClickBackButton = useCallback(() => history.push('/plans'), [history]);

  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage className="page-lesson-games">
      <div className={"page-lesson-games__header"}>
        <div className={"page-lesson-games__header__title-wrapper"}>
          <BaseButton className="page-lesson-games__header__title-wrapper__back-button mr-2" round borderless onClick={onClickBackButton}>
            <BaseIcon className="mx-n1">back</BaseIcon>
          </BaseButton>
          <ContentHeader className="page-lesson-game__header text-left">{plan?.name ?? ""}</ContentHeader>
        </div>
        <BaseButton className="page-lesson-games__header-button" name="create-plan" large={true} onClick={onCreateStart}>
          <span className="text">{"Добавить игру"}</span>
        </BaseButton>
      </div>
      {loading ? (
          <div className="page-lesson-games__spinner-container">
            <BaseSpinner size={45} />
          </div>) :
        <div/>
      }
      <div>
        {games && games.length > 0 ? (
          <div className="lesson-game-table">
            <>
              <div className="lesson-game-table__header">
                <div className="lesson-game-table__label-name">{"Название"}</div>
                <div className="lesson-game-table__label-type">{"Тип"}</div>
                <div className="lesson-game-table__label-settings">{"Параметры"}</div>
              </div>

                {games.map((item) => (
                  <LessonGameItem key={item.id} game={item} onRename={onUpdateStart} onDelete={onDeleteStart}/>
                ))}
            </>
          </div>
        ) : (loading ? <div/> : (
          <div className="page-lesson-games__no-games">{"Здесь еще ничего нет"}
            <span>{"Чтобы начать,"}&nbsp;
              <span onClick={onCreateStart} className={"page-lesson-games__no-games__active-text"}>{"добавьте игру"}
                  </span>
                </span>
          </div>
        ))}
      </div>
      <ModalWindow className={"lesson-game-item__rename-modal"} show={!!showGameUpdateModal} onClose={onUpdateClose}>
        <LessonGameUpdate planId={showGameUpdateModal ?? ""} onUpdated={onUpdated}/>
      </ModalWindow>
      <ModalWindow className={"lesson-game-item__delete-modal"} show={!!showGameDeleteModal} onClose={onDeleteClose}>
        <LessonGameDelete planId={showGameDeleteModal ?? ""} onClose={onDeleteClose} onDeleted={onDeleted}/>
      </ModalWindow>
    </BasePage>
  );
}

export default PageLessonGames;
