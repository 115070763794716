import providers from "../../../../lib/providers";

export function useController() {
  async function onUpdate(planId: string, name: string) {
    const data = { id: planId, name };
    return await providers.LessonPlanProvider.update({data});
  }

  return {
    onUpdate,
  };
}
