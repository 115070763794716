import './style.scss';
import React, {useState} from "react";
import {ImageCard} from "../../../../GameSettings";

interface Props {
  image: ImageCard;
  nextImage: () => void;
}

export default function ShadowImage({ image, nextImage }: Props) {
  const handleClick = () => {
    if(!opened) {
      setOpened(!opened);
    } else {
      nextImage();
    }
  }
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div className="shadow-image">
      <div className={opened ? "shadow-image__opened" : "shadow-image__closed"} onClick={handleClick}>
        <img className="shadow-image__image" src={image.src}/>
        <div className="shadow-image__word">
          <div className="shadow-image__word-text">{image.name}</div>
        </div>
      </div>
    </div>
  )
}