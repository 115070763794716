import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useTranslation } from "../../locales";
import ContentHeader from "../../components/ContentHeader";
import PageSettingsChangePassword from "../PageSettingsChangePassword";
import NavigationMenu from "./components/NavigationMenu";
import NavigationMenuMobile from "./components/NavigationMenuMobile";
import useViewController from "./viewController";
import MainInfoProfile from "./components/MainInfoProfile";
import BasePage from "../../components/BasePage";
import "./style.scss";
import PageSubscription from "../PageSubscription";

export default function PageUserArea() {
  const match = useRouteMatch();
  const { user, itemsList } = useViewController();
  const { t } = useTranslation();

  if (!user) {
    return <Redirect to="/" />;
  }
  const side = (
    <>
      <NavigationMenuMobile itemsList={itemsList} exact={false}/>
      <NavigationMenu itemsList={itemsList} />
    </>
  );
  return (
    <BasePage
      className="page-user-area"
      side={side}
      prepend={<ContentHeader className="page-user-area__header">{t("Настройки")}</ContentHeader>}
    >
      <Switch>
        <Route exact={true} path={`${match.url}`}>
          <Redirect to={itemsList[0].to} />
        </Route>
        <Route path={`${match.url}/settings`}>
          <MainInfoProfile />
        </Route>
        <Route path={`${match.url}/subscription`}>
          <PageSubscription />
        </Route>
        <Route path={`${match.url}/change-password`}>
          <PageSettingsChangePassword />
        </Route>
        <Route path={`*`}>
          <Redirect to={itemsList[0].to} />
        </Route>
      </Switch>
    </BasePage>
  );
}
