import {useController} from "./controller";
import {useTranslation} from "../../../../locales";
import useBaseForm from "../../../../hooks/useBaseForm";
import {FormValues} from "../../../../types";
import {validateFieldsWithException} from "../../../../lib/validation";
import {parseErrors} from "../../../../lib/requestHelpers";

export function useViewController() {
  const { t } = useTranslation();

  const { fields, values, errors, setErrors, setValues } = useBaseForm([
    {
      name: "name",
      type: "text",
      placeholder: t("Новое название"),
      validation: {
        required: true,
      },
      autocomplete: "name",
      textAlign: "center",
    },
  ]);
  const { onUpdate } = useController();
  const onChange = (values: FormValues) => setValues(values);

  const sendUpdate = async (planId: string, cb?: (newName: string) => void) => {
    try {
      validateFieldsWithException(fields, values);
      await onUpdate(planId, values.name);
      cb && cb(values.name);
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  return {
    fields,
    values,
    errors,
    setErrors,
    onChange,
    onUpdate: sendUpdate,
  };
}
