import mitt from "mitt";

const emitter = mitt();

export enum EVENTS {
  SET_LOGIN_DATA = "SET_LOGIN_DATA",
  START_PERFORMER_VERIFICATION = "START_PERFORMER_VERIFICATION",
  BALANCE_REFILL_SHOW = "BALANCE_REFILL_SHOW",
  BALANCE_REFILL_HIDE = "BALANCE_REFILL_HIDE",
  MESSAGES_NEW_MESSAGE = "MESSAGES_NEW_MESSAGE",
  MESSAGES_MARKED_READ = "MESSAGES_MARKED_READ",
  FOOTER_HIDE = "FOOTER_HIDE",
  FOOTER_SHOW = "FOOTER_SHOW",
  SET_BOOKING_USER = "SET_BOOKING_USER",
  APPOINTMENT_BANNER_HIDE = "APPOINTMENT_BANNER_HIDE",
  APPOINTMENT_BANNER_SHOW = "APPOINTMENT_BANNER_SHOW",
}
export default emitter;
