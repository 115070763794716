import useAuthUser from "../../hooks/useAuthUser";
import {LessonPlanSearchResult} from "@teacherskaya/api-provider/dist/api/LessonPlanProvider";
import {useCallback, useEffect, useState} from "react";
import providers from "../../lib/providers";
import useSubscriptionModalController from "../../modals/SubscriptionModal/subscriptionModalController";

const useController = () => {
  const { user } = useAuthUser();
  const { hasSubscription, showSubscriptionModal, onSubscription, onSubscriptionClose } = useSubscriptionModalController();

  const [state, setState] = useState<LessonPlanSearchResult | null>(null);
  const [loading, setLoading] = useState(false);

  const getData = async (page = 0, replace = true) => {
    const response = await providers.LessonPlanProvider.search({
      data: {
        userId: user?.id,
        page,
        size: 20,
        sort: "CREATE_TIME,DESC",
      },
    });
    setState((s) => {
      if (!s) return response;
      return {
        ...response,
        ...(!replace ? { result: [...s.result, ...response.result] } : {}),
      };
    });
  };

  const loadNext = useCallback(
    (cb: Function) => {
      if (!state || !state.hasNextPage) return;
      return getData(state.page + 1, false).finally(() => cb && cb());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    getData().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const onCreateStart = () => {
    if (state && state.result.length >= 3 && !hasSubscription) {
      onSubscription();
    } else {
      setShowCreateModal(true);
    }
  };

  const onCreateClose = () => {
    setShowCreateModal(false);
  };

  const onCreated = () => {
    setShowCreateModal(false);
    setLoading(true);
    getData().finally(() => setLoading(false));
  };

  const [showPlanUpdateModal, setShowUpdateModal] = useState<string | undefined>(undefined);

  const onUpdateStart = (planId: string) => {
    setShowUpdateModal(planId);
  };

  const onUpdateClose = () => {
    setShowUpdateModal(undefined);
  };

  const onUpdated = (newName: string) => {
    setState((s) => {
      if(!s) return null;
      return {
        ...s,
        result: s?.result.map(i => i.id !== showPlanUpdateModal ? i : { ...i, name: newName }),
      };
    });
    setShowUpdateModal(undefined);
  };

  const [showPlanDeleteModal, setShowDeleteModal] = useState<string | undefined>(undefined);

  const onDeleteStart = (planId: string) => {
    setShowDeleteModal(planId);
  };

  const onDeleteClose = () => {
    setShowDeleteModal(undefined);
  };

  const onDeleted = () => {
    setState((s) => {
      if(!s) return null;
      return {
        ...s,
        result: s?.result.filter(i => i.id !== showPlanDeleteModal),
      };
    });
    setShowDeleteModal(undefined);
  };

  return {
    enableScroll: state?.hasNextPage ?? !loading,
    plans: state?.result ?? [],
    loading,
    loadNext,
    user,

    showCreateModal,
    onCreateStart,
    onCreated,
    onCreateClose,

    showPlanUpdateModal,
    onUpdateStart,
    onUpdated,
    onUpdateClose,

    showPlanDeleteModal,
    onDeleteStart,
    onDeleted,
    onDeleteClose,

    showSubscriptionModal,
    onSubscription,
    onSubscriptionClose
  };
};

export default useController;
