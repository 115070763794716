import {useState} from "react";
import useAuthUser from "../../hooks/useAuthUser";

export default function useSubscriptionModalController() {
  const { hasSubscription } = useAuthUser();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const onSubscription = () => {
    if (!showSubscriptionModal) {
      setShowSubscriptionModal(true);
    }
  };

  const onSubscriptionClose = () => {
    setShowSubscriptionModal(false);
  };

  return {
    hasSubscription,
    showSubscriptionModal,
    onSubscription,
    onSubscriptionClose,
  };
}