import './style.scss';
import React from "react";
import {useDrag} from "react-dnd";
import {TIC_TAC_DND_TYPE, TicTacItem} from "../TicTacToeGamePlay";

interface Props {
  item: TicTacItem;
  disabled?: boolean;
}

export default function TicImage({ item, disabled }: Props) {
  const [, drag] = useDrag(() => ({
    type: TIC_TAC_DND_TYPE,
    item: item,
    canDrag: item.cardId === undefined && !disabled
  }), [item, disabled])

  return (
    <div className="tic_image" ref={drag}>
      <img src={item.src}/>
    </div>
  )
}