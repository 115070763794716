import React from "react";
import "./style.scss";
// import useController from "./controller";
import BaseButton from "../../../../components/BaseButton";
import ContentHeader from "../../../../components/ContentHeader";
import PageNewGame from "../../../../components/PageNewGame";
import BaseForm from "../../../../components/BaseForm";
import {useTranslation} from "../../../../locales";
import useModalLinkPrinter from "../../../../hooks/useModalLinkPrinter";
import {useViewController} from "./viewController";
import ModalWindow from "../../../../components/ModalWindow";
import LessonPlanCreate from "../../../PageLessonPlans/components/LessonPlanCreate";
import {useController} from "./controller";

interface Props {
  planId: string;
}

function LessonGameCreate({ planId }: Props) {
  const { fields, values, errors, onCreate, onChange, setErrors, showCreateModal, onCreateStart, onCreateClose, onCreated } = useViewController(planId);
  const { t } = useTranslation();
  const { getLocation, notification } = useModalLinkPrinter();

  return (
    <>
      <PageNewGame title={"Новая игра"} exitPath={'/plans/' + planId} planId={planId} onAddToPlan={onCreateStart}/>
      <ModalWindow className={"page-lesson-plans__modal"} show={showCreateModal} onClose={onCreateClose}>
        <BaseForm
          className="lesson-game-create"
          autofocus={true}
          fields={fields}
          values={values}
          errors={errors}
          notification={notification}
          onChange={onChange}
          setErrors={setErrors}
          headline={<ContentHeader>{t("Добавление игры")}</ContentHeader>}
          onSubmitEnter={() => onCreate(onCreated)}
        >
          <>
            <div className="lesson-game-create__control-wrapper __buttons">
              <BaseButton className="lesson-game-create__create" name="submit" large={true} fluid={true} onClick={() => onCreate(onCreated)}>
                {t("Добавить")}
              </BaseButton>
            </div>
          </>
      </BaseForm>
      </ModalWindow>
    </>
  );
}

export default LessonGameCreate;
