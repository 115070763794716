import React, {useState} from "react";
import "./style.scss";
import GameWithImageSettings, {ImageCard} from "../../../../GameSettings";
import {LessonGameSettings} from "../../../../../types";
import GameOptionSelection, {GameOption} from "../../../../GameSettings/components/GameOptionSelection";


interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

const cardNumbers: GameOption[] = [
  {
    optionValue: 2,
    optionText: "карточки",
    bySubscription: false,
  },
  {
    optionValue: 3,
    optionText: "карточки",
    bySubscription: true,
  },
  {
    optionValue: 4,
    optionText: "карточки",
    bySubscription: true,
  },
  {
    optionValue: 5,
    optionText: "карточек",
    bySubscription: true,
  },
];

const imageOnCardNumbers: GameOption[] = [
  {
    optionValue: 6,
    optionText: "картинок на карточке",
    bySubscription: false,
  },
  {
    optionValue: 9,
    optionText: "картинок на карточке",
    bySubscription: true,
  },
  {
    optionValue: 12,
    optionText: "картинок на карточке",
    bySubscription: true,
  },
];


export default function LottoGameSettings({ onGameConfigured }: Props) {
  const [cardNumber, setCardNumber] = useState<number>();
  const [imageOnCardNumber, setImageOnCardNumber] = useState<number>();

  const handleImageSelection = (images: ImageCard[]) => {
    const game: LessonGameSettings = {
      type: "LOTTO",
      cardCount: cardNumber,
      imageOnCardCount: imageOnCardNumber,
      imageCount: images.length,
      images: images,
    }
    onGameConfigured(game);
  }
  return (
    <div>
      {cardNumber && imageOnCardNumber ?
        <GameWithImageSettings
          selectedImageNumbers={{ optionValue: (cardNumber * imageOnCardNumber) }}
          handleImageSelection={handleImageSelection}/>
        : <div/>}
      {cardNumber && !imageOnCardNumber ?
        <GameOptionSelection
          key={"image-number-selection"}
          title={"Выберите количество картинок на одной карточке"}
          options={imageOnCardNumbers}
          handleOption={o => setImageOnCardNumber(o.optionValue)}/>
        : <div/>}
      {!cardNumber && !imageOnCardNumber ?
        <GameOptionSelection
          key={"card-number-selection"}
          title={"Выберите количество карточек для игры"}
          options={cardNumbers}
          handleOption={o => setCardNumber(o.optionValue)}/>
        : <div/>}
    </div>
  );
}
