import React from "react";
import { useTranslation } from "../../locales";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import BaseButton from "../../components/BaseButton";
import BaseForm from "../../components/BaseForm";
import BaseIcon from "../../components/BaseIcon";
import ContentHeader from "../../components/ContentHeader";
import { useViewController } from "./viewController";
import SocialLogin from "../../components/SocialLogin";
import "./style.scss";

interface Props {
  error?: string;
  onClose: () => void;
}

function ClientLogin({ error, onClose }: Props) {
  const { fields, values, errors, onLogin, onChange, setErrors } = useViewController();
  const { t } = useTranslation();
  const { getLocation, notification } = useModalLinkPrinter();

  return (
    <>
      <BaseForm
        className="client-login"
        autofocus={true}
        fields={fields}
        values={values}
        errors={(Object.values(errors).length === 0 && error) ? {"" : error} : errors}
        notification={notification}
        onChange={onChange}
        setErrors={setErrors}
        headline={<ContentHeader>{t("Вход")}</ContentHeader>}
        onSubmitEnter={() => onLogin(onClose)}
      >
        <>
          <div className="client-login__control-wrapper __buttons">
            <BaseButton
              name="resend"
              className="client-login__forgot-password fw-normal px-0"
              opacity={true}
              to={getLocation("/login/forgot")}
            >
              {t("Забыли пароль?")}
            </BaseButton>
            <BaseButton className="client-login__login" name="submit" large={true} onClick={() => onLogin(onClose)}>
              {t("Войти")}
            </BaseButton>
          </div>
          <SocialLogin className="client-login__social" onClose={onClose} title={t("или войти через")} />
          <div className="client-login__control-wrapper __switch">
            <span className="caption">{t("Еще нет аккаунта?")}</span>
            <BaseButton
              className="client-login__switch-button fw-normal"
              opacity={true}
              to={getLocation("/signup", { notification })}
            >
              <BaseIcon className="mr-1">signup</BaseIcon>
              <span>{t("Зарегистрироваться")}</span>
            </BaseButton>
          </div>
        </>
      </BaseForm>
    </>
  );
}

export default ClientLogin;
