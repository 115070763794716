import { useState } from "react";
import useAuthUser from "../../hooks/useAuthUser";
import { FieldInterface, FormValues } from "../../types";
import { parseErrors } from "../../lib/requestHelpers";
import { useTranslation } from "../../locales";
import useController from "./controller";

function useViewController() {
  const { user } = useAuthUser();
  const { t } = useTranslation();

  const initialState: FormValues = {
    screenName: user?.screenName,
    email: user?.email,

    about: "",
    website: "",
  };

  let fields: FieldInterface[] = [
    {
      name: "screenName",
      type: "text",
      label: t("Имя"),
      validation: {
        required: true,
      },
      largeText: true,
    },
    {
      name: "email",
      type: "email",
      label: t("Email"),
      validation: {
        required: true,
      },
      disabled: true,
      iconLeft: "mail",
    },
  ];

  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loadingForm, setLoadingForm] = useState(false);
  const onChange = (values: FormValues) => setValues(values);

  const { onSaveFields } = useController();

  const onSave = async () => {
    try {
      setLoadingForm(true);
      await onSaveFields(fields, values);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setLoadingForm(false);
    }
  };

  return {
    fields,
    values,
    onChange,
    onSave,
    errors,
    setErrors,
    loadingForm,
    role: user?.role
  };
}

export default useViewController;
