import providers from "../../lib/providers";

export function useController() {
  async function onLogin(data: { email: string; password: string }) {
    return await providers.LoginProvider.login(data);
  }

  return {
    onLogin,
  };
}
