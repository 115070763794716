import React from "react";
import "./style.scss";
import useSubscriptionModalController from "../../../../modals/SubscriptionModal/subscriptionModalController";
import SubscriptionModal from "../../../../modals/SubscriptionModal";
import BaseIcon from "../../../BaseIcon";

interface Props {
  title?: String;
  options: GameOption[];
  handleOption: (i: GameOption) => void;
}

export interface GameOption {
  optionValue: number;
  optionTitle?: string;
  optionText?: string;
  bySubscription?: boolean;
}

export default function GameOptionSelection({ title, options, handleOption }: Props) {
  const { hasSubscription, showSubscriptionModal, onSubscription, onSubscriptionClose } = useSubscriptionModalController();
  const cls = "game-option-selection";

  const imageNumberWithAuth = options.map(i => ({
    ...i,
    bySubscription: i.bySubscription ? !hasSubscription : false,
  }));

  const openLoginOrSelectNumber = (n: GameOption) => {
    if (n.bySubscription && !hasSubscription) {
      onSubscription();
    } else {
      handleOption(n);
    }
  }

    return (
        <div className={cls}>
            <div className={cls + "__title"}>{title ?? "Выберите количество уникальных картинок для игры"}</div>
            <div className={cls + "__cards"}>
            {imageNumberWithAuth.map(n =>
              <div key={n.optionValue} className={cls + "__card " + (n.bySubscription && !hasSubscription ? "__subscription" : "__available")} onClick={() => openLoginOrSelectNumber(n)}>
                {n.bySubscription ? <BaseIcon className={cls + "__card-subscription"}>crown</BaseIcon> : <div/>}
                <div className={cls + "__card-text-wrapper"}>
                  <div className={cls + "__card-number"}>{n.optionTitle ?? n.optionValue}</div>
                  {n.optionText ?
                    <div className={cls + "__card-text"}>{n.optionText}</div>
                    : <div/>}
                </div>
              </div>
            )}
            </div>
          <SubscriptionModal show={showSubscriptionModal} onClose={onSubscriptionClose}/>
        </div>
    );
}
