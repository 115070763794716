import React, {useEffect, useState} from "react";
import "./style.scss";
import ShadowImage from "../ShadowImage";
import {ImageCard} from "../../../../GameSettings";
import BaseButton from "../../../../../components/BaseButton";
import BaseIcon from "../../../../../components/BaseIcon";
import useCacheImagesController from "../../../../../lib/cacheImages";
import BaseSpinner from "../../../../../components/BaseSpinner";


interface Props {
    images: ImageCard[];
}

export default function ShadowGamePlay({ images }: Props) {
    const [currentImageNumber, setCurrentImageNumber] = useState<number>(0);
    const [img, setImages] = useState<ImageCard[]>(images);
    const {loading, setLoading, cacheImages} = useCacheImagesController();

    useEffect(() => {
        setImages(prev => prev.sort(() => Math.random() - 0.5));
        setLoading(true);
        cacheImages([img[currentImageNumber]], true);
        cacheImages(img);
    }, []);

    const nextImage = () => {
        if(images.length > currentImageNumber) {
            setCurrentImageNumber(prev => prev + 1);
        }
    }

    const prevImage = () => {
        if(currentImageNumber > 0) {
            setCurrentImageNumber(prev => prev - 1);
        }
    }

    return (
        <div>
            {loading ? (
                <div className="shadow-game__spinner-container">
                    <BaseSpinner size={45} />
                </div>) :
            <div>
            <div className="shadow-game__card">
                <ShadowImage key={img[currentImageNumber].name} image={img[currentImageNumber]} nextImage={nextImage}/>
            </div>
                <div className="shadow-game__game-buttons">
                    <BaseButton
                        className="shadow-game__back-button mr-2"
                        disabled={currentImageNumber <= 0}
                        round
                        large={true}
                        onClick={prevImage}
                    >
                        <BaseIcon>back</BaseIcon>
                    </BaseButton>
                    <BaseButton
                        className="shadow-game__next-button mr-2"
                        disabled={currentImageNumber >= img.length - 1}
                        round
                        large={true}
                        onClick={nextImage}
                    >
                        <BaseIcon className="shadow-game__next-icon-button">back</BaseIcon>
                    </BaseButton>
                </div>
            </div>}
        </div>
    );
}
