import React from "react";
import "./styles.scss";

interface Props {
  className?: string;
}

export default function FullWidthPageBackground({ className }: Props) {
  const cls = ["full-width-page-background"];
  if (className) cls.push(className);

  return <div className={cls.join(" ")} />;
}