import React, {useEffect, useState} from "react";
import "./style.scss";
import {ImageCard} from "../../../../GameSettings";
import useCacheImagesController from "../../../../../lib/cacheImages";
import BaseSpinner from "../../../../../components/BaseSpinner";
import TicTacToeCard from "../TicTacToeCard";
import {TicTacToeCardItem} from "../TicTacToeCardImage";
import TicTacImages from "../TicImages";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import config from "../../../../../config";

interface Props {
    images: ImageCard[];
}

export interface TicTacItem {
  id: number;
  type: TicTacType;
  src: string;
  cardId?: number;
}

export enum TicTacType {
  TIC,
  TAC
}

export const TIC_TAC_DND_TYPE = 'TIC_TAC_DND_TYPE';

const checkDirectionWin = (xF: (i: number) => number,
                           yF: (i: number) => number,
                           count: number,
                           items: TicTacToeCardItem[]) => {
  const cards = [];
  for (let i = 0; i < count; i++) {
    const item = items.find(it => it.xId === xF(i) && it.yId === yF(i) && it.item);
    if (item) cards.push(item);
  }
  if (cards.length !== count) {
    return items;
  }
  const type = cards[0]!.item!.type;
  if (cards.every(c => c!.item!.type === type)) {
    const cardIds = cards.map(c => c!.id);
    return items.map(i => !cardIds.includes(i.id) ? i : {...i, win: true});
  }
  return items;
}

const checkWin = (items: TicTacToeCardItem[], winCount: number) => {
  let res = items;
  for (let i = 0; i < items.length; i++) {
    if (!items[i].item) continue;
    const x = items[i].xId;
    const y = items[i].yId;
    res = checkDirectionWin((i) => x + i, () => y, winCount, res);
    res = checkDirectionWin(() => x, (i) => y + i, winCount, res);
    res = checkDirectionWin((i) => x + i, (i) => y + i, winCount, res);
    res = checkDirectionWin((i) => x + i, (i) => y - i, winCount, res);
  }
  return res;
}

export default function TicTacToeGamePlay({ images }: Props) {
  const [ticTacToeImages, setTicTacToeImages] = useState<TicTacToeCardItem[]>([]);
  const [ticTacItems, setTicTacItems] = useState<TicTacItem[]>([]);
  const [turn, setTurn] = useState<TicTacType>();
  const {loading, cacheImages} = useCacheImagesController();
  const gridSize = images.length === 25 ? 5 : 3;
  const winCount = images.length === 25 ? 4 : 3;
  const ticTacCount = images.length === 25 ? 30 : 12;

  const shuffleTicTacToeImages = () => {
    const randomImages = images.sort(() => Math.random() - 0.5);
    setTicTacToeImages([...Array(randomImages.length).keys()].map(i => ({
      id: i,
      src: randomImages[i].src,
      xId: Math.floor(i / gridSize),
      yId: i % gridSize,
      win: false
    })));
  }

  const generateTicTacItems = () => {
    setTicTacItems([...Array(ticTacCount).keys()].map(i => ({
      id: i,
      type: i % 2 == 0 ? TicTacType.TIC : TicTacType.TAC,
      src: i % 2 == 0 ? config.assetsUrl + "/tic.png" : config.assetsUrl + "/tac.png"
    })));
  }

  useEffect(() => {
    shuffleTicTacToeImages();
    generateTicTacItems();
    cacheImages(images, true);
  }, []);

  const handleDrop = (item: any, cardId: number) => {
    setTicTacItems(prev => prev.map(i => i.id !== item.id ? i : {
      ...i,
      cardId: cardId
    }));
    setTicTacToeImages(prev => {
      return checkWin(prev.map(i => i.id !== cardId ? i : {...i, item: { ...item, cardId: cardId }}), winCount);
    });
    setTurn(() => (item.type === TicTacType.TIC ? TicTacType.TAC : TicTacType.TIC));
  };

  return (
    <div>
      {loading ? (
          <div className="tictactoe-game__spinner-container">
            <BaseSpinner size={45} />
          </div>) :
        <DndProvider backend={HTML5Backend}>
          <div className="tictactoe-game__fields">
            <div className="tictactoe-game__tic-field">
              <TicTacImages key="tic"
                            items={ticTacItems.filter(i => i.type === TicTacType.TIC)}
                            disabled={(turn !== undefined && turn !== TicTacType.TIC) || !!ticTacToeImages.find(i => i.win)}
              />
            </div>
            <div className="tictactoe-game__image-field">
              <TicTacToeCard images={ticTacToeImages} gridSize={gridSize} moveItem={handleDrop}/>
            </div>
            <div className="tictactoe-game__tac-field">
              <TicTacImages key="tac"
                            items={ticTacItems.filter(i => i.type === TicTacType.TAC)}
                            disabled={(turn !== undefined && turn !== TicTacType.TAC) || !!ticTacToeImages.find(i => i.win)}
              />
            </div>
          </div>
        </DndProvider>
      }
      <div>

      </div>
    </div>
  );
}
