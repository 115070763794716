import './style.scss';
import React from "react";
import {useDrop} from "react-dnd";
import {TIC_TAC_DND_TYPE, TicTacItem} from "../TicTacToeGamePlay";
import TicImage from "../TicImage";

export interface TicTacToeCardItem {
  id: number;
  xId: number;
  yId: number;
  src: string;
  win: boolean;
  item?: TicTacItem;
}

interface Props {
  cardItem: TicTacToeCardItem;
  moveItem: (i: any, cardId: number) => void;
}

export default function TicTacToeCardImage({ cardItem, moveItem }: Props) {
  const [, drop] = useDrop(() => ({
    accept: TIC_TAC_DND_TYPE,
    canDrop: () => !cardItem.item,
    drop: (item) => moveItem(item, cardItem.id)
  }), [cardItem])

  return (
    <div className="tictactoe_card_image" ref={drop}>
      {cardItem.item ?
        <div className="tictactoe_card_image__tictacitem" style={cardItem.win ? {backgroundColor:"#d6587f5e"} : {}}>
          <TicImage item={cardItem.item}/>
        </div>
        : <></>}
      <div className="tictactoe_card_image__image" style={cardItem.item ? {opacity:0} : {}}>
        <img src={cardItem.src}/>
      </div>
    </div>
  )
}