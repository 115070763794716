import React from "react";
import "./style.scss";
import BaseButton from "../../../../components/BaseButton";
import {useController} from "./controller";

interface Props {
  planId: string;
  onClose: () => void;
  onDeleted: () => void;
}

function LessonPlanDelete({ planId, onClose, onDeleted }: Props) {
  const { onDelete } = useController();

  const sendDelete = async (planId: string, cb?: () => void) => {
    await onDelete(planId);
    cb && cb();
  };

  return (
    <div className={"landing-plan-update__confirm-delete"}>
      <h2 className="headline-1 text-center">{"Вы действительно хотите удалить этот план?"}</h2>
      <div className="landing-plan-update__confirm-delete-buttons">
        <BaseButton
          large={true}
          outline={true}
          onClick={onClose}
          className="landing-plan-update__confirm-delete-btn"
        >
          {"Отмена"}
        </BaseButton>
        <BaseButton
          name="confirm-delete"
          large={true}
          negative={true}
          onClick={() => sendDelete(planId, onDeleted)}
          className="landing-plan-update__confirm-delete-btn"
        >
          {"Удалить"}
        </BaseButton>
      </div>
    </div>
  );
}

export default LessonPlanDelete;
