import React from "react";
import "./style.scss";
import {SelectedNumber} from "../HiddenNumberGamePlay";

interface Props {
  numbers: SelectedNumber[];
}

export default function HiddenAllSelectedNumber({ numbers }: Props) {
  return (
    <div className="hidden-all-selected-numbers">
      {numbers.map(
        i => (
          <div key={i.id} className="hidden-all-selected-numbers__number-card">
            <div key={i.id} className="hidden-all-selected-numbers__number-card__number">
              {i.id}{i.inequalitySign}
            </div>
          </div>
        )
      )}
    </div>
  );
}
