import React, {useCallback} from "react";
import "./style.scss";
// import useController from "./controller";
import {useController} from "./controller";
import PageGamePlay from "../../../../components/PageGamePlay";
import BaseSpinner from "../../../../components/BaseSpinner";
import {useHistory} from "react-router-dom";
import {LocationState} from "../../../../types";

interface Props {
  planId: string;
  gameId: string
}

function LessonGamePlay({ planId, gameId }: Props) {
  const history = useHistory<LocationState>();
  const { plan, game, loading } = useController(planId, gameId);


  const onClickBackButton = useCallback(() => {
    history.push('/plans/' + planId);
  }, [history]);


  return (
    <>
      {loading || !game ? (
          <div className="page-lesson-games__spinner-container">
            <BaseSpinner size={45} />
          </div>) :
        <PageGamePlay game={game} onClickBackButton={onClickBackButton}/>
      }
    </>
  );
}

export default LessonGamePlay;
