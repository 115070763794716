import {useController} from "./controller";
import {useTranslation} from "../../../../locales";
import useBaseForm from "../../../../hooks/useBaseForm";
import {FormValues} from "../../../../types";
import {validateFieldsWithException} from "../../../../lib/validation";
import {parseErrors} from "../../../../lib/requestHelpers";

export function useViewController(planId: string) {
  const { t } = useTranslation();

  const { fields, values, errors, setErrors, setValues } = useBaseForm([
    {
      name: "name",
      type: "text",
      placeholder: t("Название"),
      validation: {
        required: true,
      },
      autocomplete: "name",
      textAlign: "center",
    },
  ]);
  const { onCreate, showCreateModal, onCreateStart, onCreateClose, onCreated } = useController(planId);
  const onChange = (values: FormValues) => setValues(values);

  const sendCreate = async (cb?: () => void) => {
    try {
      validateFieldsWithException(fields, values);
      await onCreate(values.name);
      cb && cb();
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  return {
    fields,
    values,
    errors,
    setErrors,
    onChange,
    onCreate: sendCreate,
    showCreateModal,
    onCreateStart,
    onCreateClose,
    onCreated
  };
}
