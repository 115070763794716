import React, {useRef, useState} from "react";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";

interface Props {
  item: FAQItem;
  className: string;
}

export interface FAQItem {
  question: string;
  answer: string;
  image?: string;
}

export default function SubscriptionFaqItem({ item, className }: Props) {
  const [active, setActive] = useState(false);
  const cls = "subscription-faq-item";
  const clsWrapper = ["subscription-faq-item"];
  clsWrapper.push(className);
  if (active) clsWrapper.push("__active");
  const ref = useRef<HTMLDivElement>(null);

  const onClick = () => {
    if (ref.current) {
      ref.current.style.height = (!active ? ref.current.scrollHeight : 0) + "px";
    }
    setActive(!active);
  };

  return (
    <div className={clsWrapper.join(" ")} onClick={onClick}>
      <div className={cls + "__title"}>
        <span className={cls + "__question pr-4"}>{item.question}</span>
        <span className={cls + "__question-icon-wrapper d-center"}>
          <BaseIcon className={cls + "__question-icon"}>close</BaseIcon>
        </span>
      </div>
      <div className={cls + "__text"} ref={ref}>
        {item.answer}
        {item.image ? <img src={item.image} alt={"image.png"}/> : <div/>}
      </div>
    </div>
  );
}
