import React from "react";
import "./style.scss";
import GameWithImageSettings, {ImageCard} from "../../../../GameSettings";
import {GameOption} from "../../../../GameSettings/components/GameOptionSelection";
import {LessonGameSettings} from "../../../../../types";


interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

const imageNumbers: GameOption[] = [
  {
    optionValue: 10,
    bySubscription: false,
  },
  {
    optionValue: 15,
    bySubscription: false,
  },
  {
    optionValue: 20,
    bySubscription: true,
  },
  {
    optionValue: 30,
    bySubscription: true,
  },
  {
    optionValue: 40,
    bySubscription: true,
  },
  {
    optionValue: 50,
    bySubscription: true,
  },
];

export default function ShadowGameSettings({ onGameConfigured }: Props) {
    const handleImageSelection = (images: ImageCard[]) => {
      const game: LessonGameSettings = {
        type: "SHADOW",
        imageCount: images.length,
        images: images,
      }
      onGameConfigured(game);
    }
    return (
      <GameWithImageSettings gameOptions={imageNumbers} handleImageSelection={handleImageSelection}/>
    );
}
