import { useMemo } from "react";
import { useOrientation, useWindowSize } from "react-use";

const MOBILE_SM = 320;
const MOBILE = 428;
const TABLET = 768;
const DESKTOP = 1000;

export default function useBreakPoints() {
  const { width } = useWindowSize();
  const { angle } = useOrientation();

  const mobileSMOnly = useMemo(() => width <= MOBILE_SM, [width]);
  const mobileUp = useMemo(() => width >= MOBILE, [width]);
  const mobileOnly = useMemo(() => width >= MOBILE && width < TABLET, [width]);
  const tabletUp = useMemo(() => width >= TABLET, [width]);
  const tabletOnly = useMemo(() => width >= TABLET && width < DESKTOP, [width]);
  const desktopUp = useMemo(() => width >= DESKTOP, [width]);
  const mobileLandscape = useMemo(() => [90, 270].includes(Math.abs(angle)) && !desktopUp, [desktopUp, angle]);

  return {
    mobileSMOnly,
    mobileUp,
    mobileOnly,
    tabletUp,
    tabletOnly,
    desktopUp,
    mobileLandscape,
  };
}
