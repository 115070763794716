import React from "react";
import PageLoading from "../../components/ElementLoading";
import useController from "./controller";

interface Props {
    token: string;
}

export default function PageLoginToken({ token }: Props) {
    const { loading } = useController(token);
    return <PageLoading loading={loading} />;
}