import React from "react";
import ModalWindow from "../../components/ModalWindow";
import ClientSignUp from "../ClientSignUp";

interface Props {
  showSignUpModal: boolean;
  onClose: () => void;
}

function ModalSignUp({ showSignUpModal, onClose }: Props) {
  return (
    <>
      <ModalWindow className={"sign-up-modal"} show={showSignUpModal} onClose={onClose}>
        <ClientSignUp onClose={onClose}/>
      </ModalWindow>
    </>
  );
}

export default ModalSignUp;
