import {useState} from "react";
import useAuthUser from "../../hooks/useAuthUser";

export default function useModalSignUpController() {
  const { user: loggedUser } = useAuthUser();
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const isAuth = !!loggedUser;


  const onSignUp = () => {
    if (!showSignUpModal) {
      setShowSignUpModal(true);
    }
  };

  const onClose = () => {
    setShowSignUpModal(false);
  };

  return {
    isAuth,
    showSignUpModal,
    onSignUp,
    onClose,
  };
};