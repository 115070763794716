import React, {MouseEvent, useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import "./style.scss";
import useAuthUser from "../../hooks/useAuthUser";
import config from "../../config";

const COOKIE_ACCEPTED = "COOKIE_ACCEPTED";

export default function CookieBanner() {
  const [showCookie, setShowCookie] = useState<boolean>(false);
  const nodeRef = useRef<HTMLDivElement>(null);
  const { user } = useAuthUser();

  useEffect(() => {
    if (user) {
      localStorage.setItem(COOKIE_ACCEPTED, "true");
    }
    setShowCookie(localStorage.getItem(COOKIE_ACCEPTED) === null)
  }, []);

  const onClickExit = (event: MouseEvent) => {
    event.stopPropagation();
    setShowCookie(false)
  }

  const onClickAccept = (event: MouseEvent) => {
    event.stopPropagation();
    localStorage.setItem(COOKIE_ACCEPTED, "true");
    setShowCookie(false)
  }

  return (
    <>
      <CSSTransition
        in={showCookie}
        nodeRef={nodeRef}
        unmountOnExit
        timeout={{ enter: 5000, exit: 2000 }}
        classNames="slide-right"
      >
        <div
          className="cookie"
          ref={nodeRef}
        >
          <div className="cookie__wrapper">
            <span className="cookie__text">
              {"Продолжая пользоваться сайтом, вы даете согласие на использование "}
              <a className="footer__link" href={config.s3Url + "/policies/privacy_policy.pdf"} target="_blank">
                файлов cookie
              </a>
            </span>
            <BaseButton className="cookie__agree" fluid={true} onClick={onClickAccept}>
              Принять
            </BaseButton>
          </div>
          <BaseButton
            className="cookie__close"
            opacity={true}
            onClick={onClickExit}
            icon={true}
          >
            <BaseIcon size={18}>close</BaseIcon>
          </BaseButton>
        </div>
      </CSSTransition>
    </>
  );
}
