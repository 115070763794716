import React from "react";
import "./style.scss";
import {InequalitySign} from "../HiddenNumberGamePlay";

interface Props {
  number: number | undefined;
  disabled: boolean;
  handleClick: (item: number | undefined, sign: InequalitySign) => void;
}

export default function HiddenSelectedNumber({ number, disabled, handleClick}: Props) {
  const handle = (number: number | undefined, sign: InequalitySign) => {
    if (disabled || !number) {
      return;
    }
    handleClick(number, sign);
  }

  return (
    <div className="hidden-selected-number">
      <div className="hidden-selected-number__number" style={disabled ? {backgroundColor:"#dec2fb"} : {}}>{number}</div>
      <div className="hidden-selected-number__signs">
        <div className={"hidden-selected-number__signs__sign"} onClick={() => handle(number, InequalitySign.MORE)}>
          {InequalitySign.MORE}
        </div>
        <div className={"hidden-selected-number__signs__sign"} style={disabled ? {backgroundColor:"#dec2fb"} : {}} onClick={() => handle(number, InequalitySign.EQUAL)}>
          {InequalitySign.EQUAL}
        </div>
        <div className={"hidden-selected-number__signs__sign"} onClick={() => handle(number, InequalitySign.LESS)}>
          {InequalitySign.LESS}
        </div>
      </div>
    </div>
  );
}
