import React, {useEffect, useState} from "react";
import "./style.scss";
import {ImageCard} from "../../../../GameSettings";
import LottoCard from "../LottoCard";
import {LottoCardItem} from "../LottoCardImage";
import useCacheImagesController from "../../../../../lib/cacheImages";
import BaseSpinner from "../../../../../components/BaseSpinner";

interface Props {
    cardNumber: number;
    imagesOnCard: number;
    images: ImageCard[];
}

function splitArrayEvenly(array: LottoCardItem[], n: number) {
  array = array.slice();
  let result = [];
  while (array.length) {
    result.push(array.splice(0, Math.ceil(array.length / n--)));
  }
  return result;
}

export default function LottoGamePlay({ cardNumber, images }: Props) {
  const [lottoImages, setLottoImages] = useState<LottoCardItem[]>([]);
  const [cardImages, setCardImages] = useState<LottoCardItem[][]>([[]]);
  const [lottoImageNumber, setLottoImageNumber] = useState<number>(0);
  const [isLottoImageFlipped, setLottoImageFlipped] = useState<boolean>(false);
  const {loading, cacheImages} = useCacheImagesController();

  const shuffleLottoImages = () => {
    setLottoImageNumber(0);
    setLottoImageFlipped(false);

    setLottoImages(images.sort(() => Math.random() - 0.5).map((i) => ({...i, shown: false})));
    setCardImages(splitArrayEvenly(images.sort(() => Math.random() - 0.5).map((i) => ({...i, shown: false})), cardNumber));
  }

  useEffect(() => {
    cacheImages(images, true);
    shuffleLottoImages();
  }, []);

  const handleLottoImageClick = () => {
    if (!isLottoImageFlipped) {
      setLottoImages(prev => prev.map(i => i.src === lottoImages[lottoImageNumber].src ? {
        ...i,
        shown: true
      } : i));
      setCardImages(prevCards => prevCards.map(
        card => card.map(i => i.src === lottoImages[lottoImageNumber].src ? {
          ...i,
          shown: true
        } : i)));
      setLottoImageFlipped(true);
    } else {
      //todo обработать концовку
      setLottoImageNumber(lottoImageNumber < lottoImages.length ? lottoImageNumber + 1 : lottoImageNumber);
      setLottoImageFlipped(false);
    }
  };


  return (
    <div>
      {loading ? (
          <div className="memory-game__spinner-container">
            <BaseSpinner size={45} />
          </div>) : <div>
      {cardImages.length > 1 ? (<div className="lotto-game__cards">
          <div><LottoCard key={0} images={cardImages[0]} color={"#d6587f"}/></div>
          <div className="lotto-game__lotto-image-card" style={{gridRowStart: cardImages.length === 4 ? "span 2" : "1"}}>
            {lottoImageNumber < lottoImages.length ?
              <img className="lotto-game__lotto-image" src={lottoImages[lottoImageNumber].src}
                   onClick={handleLottoImageClick}/> : <div/>
            }
          </div>
          <div><LottoCard key={1} images={cardImages[1]} color={"#3f70f6"}/></div>
          {cardImages.length < 3 ? <div/>
            : <div style={{gridColumnStart: (cardImages.length === 3 ? 2 : 1)}}>
              <LottoCard key={2} images={cardImages[2]} color={"#fcb764"}/></div>
          }
          {cardImages.length < 4 ? <div/>
            : <div style={{gridColumnStart: (cardImages.length === 4 ? 3 : 2)}}><LottoCard key={3} images={cardImages[3]} color={"#bf94c6"}/></div>
          }
          {cardImages.length < 5 ? <div/>
            : <div style={{gridColumnStart: 3}}><LottoCard key={4} images={cardImages[4]} color={"#78c6bb"}/></div>
          }
        </div>)
        : <div/>}
      </div>}
    </div>
  );
}
