import React from "react";
import {LessonGameSettings} from "../../../../../types";
import GameOptionSelection, {GameOption} from "../../../../GameSettings/components/GameOptionSelection";

interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

const cardNumbers: GameOption[] = [
  {
    optionValue: 50,
    optionText: "чисел",
    bySubscription: false,
  },
  {
    optionValue: 100,
    optionText: "чисел",
    bySubscription: true,
  },
];


export default function HiddenNumberGameSettings({ onGameConfigured }: Props) {

  const handleCardNumber = (n: GameOption) => {
      const game: LessonGameSettings = {
        type: "HIDDEN_NUMBER",
        cardCount: n.optionValue,
        images: [],
      }
      onGameConfigured(game);
  }

  return (
    <div>
      <GameOptionSelection
        key={"card-number-selection"}
        title={"Выберите количество чисел для игры"}
        options={cardNumbers}
        handleOption={handleCardNumber}/>
    </div>
  );
}
