import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "../../locales";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import BaseAvatar from "../BaseAvatar";
import BaseMenu from "../BaseMenu";
import useViewController from "./viewController";
import Navigation from "./components/Navigation";
import "./style.scss";
import PromoCodeActivation from "../../modals/PromoCodeActivation";

function PageHeader() {
  const {
    logoSrc,
    setDefaultLogo,
    logoSrcMobile,
    itemsList,
    user,
    hasSubscription,
    showPromoCodeActivationModal,
    onPromoCodeActivationStart,
    onPromoCodeActivationClose,
  } = useViewController();

  const { getLocation } = useModalLinkPrinter();
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="header__container __row container">
        <div className="header__inner">
          <Link to="/" className="header__logo">
            <img className={"header__logo-img __desktop"} src={logoSrc} alt="logo" onError={setDefaultLogo} />
            <img className={"header__logo-img __mobile"} src={logoSrcMobile} alt="logo" onError={setDefaultLogo} />
          </Link>
          <Navigation className="header__desktop-nav" isAuthUser={user !== null}/>
          {/*{ user && !hasSubscription &&*/}
          {/*  (<BaseButton className="header__promocode-button" live onClick={onPromoCodeActivationStart}>*/}
          {/*    Ввести промокод*/}
          {/*  </BaseButton>)*/}
          {/*}*/}
          {user ? (
            <div className="header__logged-user">
              <BaseMenu
                className="header__base-menu"
                itemsList={itemsList}
                title={user.screenName ?? ""}
                spaced={true}
              >
                <div className="header__logged-user-menu">
                  <BaseAvatar user={user} className="header__logged-user-avatar" size={40} />
                  <BaseIcon size={12.5}>fas-angle-down</BaseIcon>
                </div>
              </BaseMenu>
            </div>
          ) : (
            <div className="header__buttons">
              <BaseButton className="header__buttons-button" borderless={true} to={getLocation("/login")}>
                <BaseIcon>signin</BaseIcon>
                <span className="text">{t("Вход")}</span>
              </BaseButton>
              <BaseButton className="header__buttons-button __signup" to={getLocation("/signup")}>
                <BaseIcon>signup</BaseIcon>
                <span className="text">{t("Регистрация")}</span>
              </BaseButton>
            </div>
          )}
        </div>
        <Navigation className="header__mobile-nav" isAuthUser={user !== null}/>
      </div>
      { user && !hasSubscription &&
        <PromoCodeActivation show={showPromoCodeActivationModal} onClose={onPromoCodeActivationClose}/> }
    </header>
  );
}

export default PageHeader;
