import './style.scss';
import React from "react";
import TicImage from "../TicImage";
import {TicTacItem} from "../TicTacToeGamePlay";

interface Props {
  items: TicTacItem[];
  disabled?: boolean;
}

export default function TicTacImages({ items, disabled }: Props) {
  return (
    <div className="tic_images" style={{
      gridTemplateColumns: "1fr ".repeat(items.length > 6 ? 3 : 2),
    }}>
      {items.map(i => i.cardId !== undefined ? <div key={i.id}/> : <TicImage key={i.id} item={i} disabled={disabled}/>)}
    </div>
  )
}