import React from "react";
import { NavLink } from "react-router-dom";
import { BaseMenuItemInterface } from "../../types";
import "./style.scss";

export interface BaseTabsProps {
  itemList: BaseMenuItemInterface[];
  selected?: BaseMenuItemInterface;
  onClick?: (item: BaseMenuItemInterface) => void;
  gradual?: boolean;
  className?: string;
}

function BaseTabs({ className, itemList, selected, gradual = false, onClick }: BaseTabsProps) {
  const cls = ["base-tabs"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      {itemList.map((item, index) => {
        const itemCls = ["base-tabs__item py-3"];
        const selectedIndex = itemList.findIndex((i) => i.name === selected?.name);
        if (index === selectedIndex) itemCls.push("__active");
        if (gradual && index < selectedIndex) itemCls.push("__passed");
        if (gradual && index > selectedIndex) itemCls.push("__inactive");
        if (item.className) itemCls.push(item.className);
        const canClick = !gradual || index < selectedIndex;
        if (item.to) {
          return (
            <NavLink exact key={item.name} className={itemCls.join(" ")} to={item.to}>
              {item.title}
            </NavLink>
          );
        } else {
          return (
            <div className={itemCls.join(" ")} onClick={() => canClick && onClick && onClick(item)} key={item.name}>
              {item.title}
            </div>
          );
        }
      })}
    </div>
  );
}

export default BaseTabs;
