import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import useAuthUser from "../../hooks/useAuthUser";
import useMounted from "../../hooks/useMounted";

export default function Logout() {
  const { setLogout } = useAuthUser();
  const [redirect, setRedirect] = useState(false);
  const mounted = useMounted();

  useEffect(() => {
    setLogout().then(() => {
      if (mounted.current) setRedirect(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return redirect ? <Redirect to="/" /> : null;
}
