import './style.scss';
import React from "react";
import config from "../../../../../config";

export interface MemoryCardItem {
  id: number;
  src: string;
  matched: boolean;
}

interface Props {
  card: MemoryCardItem;
  flipped: boolean;
  disabled: boolean;
  handleChoice: (card: MemoryCardItem) => void;
}

export default function MemoryCard({ card, flipped, disabled, handleChoice }: Props) {
  const handleClick = () => {
    if(!disabled) {
      handleChoice(card);
    }
  }

  return (
    <div className="memory_card">
      <div className={flipped ? "memory_card__flipped" : ""}>
        <img className="memory_card__front" src={card.src} alt="card front"/>
        <div className="memory_card__back">
          <div className="memory_card__back__text">{card.id}</div>
          <img src={config.assetsUrl + "/memory-card-cover.jpg"} onClick={handleClick} alt="card back"/>
        </div>
      </div>
    </div>
  )
}