import React, {useCallback} from "react";
import "./style.scss";
import {LessonGame} from "@teacherskaya/api-provider/dist/api/LessonGameProvider";
import BaseIcon from "../../../../components/BaseIcon";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import {useHistory} from "react-router-dom";
import {GAME_TYPES} from "../../../../const";
import BasePhoto from "../../../../components/BasePhoto";
import {LocationState} from "../../../../types";

interface Props {
  game: LessonGame;
  onRename(gameId: string): void;
  onDelete(gameId: string): void;
}

function LessonGameItem({
                          game,
                          onRename,
                          onDelete,
                        }: Props) {
  const history = useHistory<LocationState>();
  const { ref, isComponentVisible, toggleComponentVisible: toggleMenu} = useComponentVisible(false);
  const gameType = GAME_TYPES.find(g => g.type === game.type);

  const onRenameInner = () => {
    onRename(game.id);
    toggleMenu();
  }

  const onDeleteInner = () => {
    onDelete(game.id);
    toggleMenu();
  }

  const onGameClick = useCallback(() => {
    history.push("/plans/" + game.planId + "/games/" + game.id);
  }, [history]);

  return (
    <>
      <li className="lesson-game-item" onClick={onGameClick}>
        <div onClick={() => {}} className="lesson-game-item__name">
          <h3>{game.name}</h3>
        </div>
        <div className="lesson-game-item__type">
          <BasePhoto src={gameType?.imgSrc} alt="Превью" title={"Нет картинки"} photoWidth={30}/>
          <span>{gameType?.name}</span>
        </div>
        <div className="lesson-game-item__settings">
          {game.cardCount > 0 ? <div>{game.cardCount + " карточки"}<br/></div> : <div/>}
          {game.imageOnCardCount > 0 ? <div>{game.imageOnCardCount + " картинок на карточке"}<br/></div> : <div/>}
          {game.imageCount > 0 ? <div>{game.imageCount + " картинок"}</div> : <div/>}
        </div>

        <div ref={ref} onClick={e => e.stopPropagation()}>
          <BaseIcon onClick={toggleMenu} className="lesson-game-item__more-icon">
            more
          </BaseIcon>
        {isComponentVisible && (
          <div className="lesson-game-item__dots-menu">
            <div onClick={onRenameInner} className="lesson-game-item__dots-menu-element">Переименовать</div>
            <div onClick={onDeleteInner} className="lesson-game-item__dots-menu-element red">Удалить</div>
          </div>
        )}
        </div>
      </li>
    </>
  )
}

export default LessonGameItem;