import React, {useEffect, useRef, useState} from "react";
import BaseIcon from "../../../../components/BaseIcon";
import "./style.scss";
import {ImageCard} from "../../index";
import GameImage from "../GameImage";

interface Props {
  categoryName: string;
  images: ImageCard[];
  handleSelect: (card: ImageCard, selected?: boolean) => void;
  bySubscription: boolean;
  activeCategory?: boolean;
}

export default function GameImageCategory({ categoryName,
                                            images,
                                            bySubscription,
                                            activeCategory = false,
                                            handleSelect }: Props) {
  const [active, setActive] = useState(activeCategory);
  const [selectedCount, setSelectedCount] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const cls = "game-image-category";

  useEffect(() => {
    if (active && ref.current) {
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }, []);

  const onClick = () => {
    if (ref.current) {
      ref.current.style.height = (!active ? ref.current.scrollHeight : 0) + "px";
    }
    setActive(!active);
  };

  const selectAll = () => {
      const selected = images.length === selectedCount;
      images.forEach(i => handleSelect(i, !selected));
  };

  useEffect(() => {
    setSelectedCount(images.filter(i => i.selected).length);
  }, [images]);

  return (
    <div className={cls + " mb-3 " + (active ? "__active " : "") + (bySubscription ? "__subscription" : "")}>
      <div className={cls + "__title"} onClick={onClick}>
        <div className={cls + "__name-wrapper"}>
          { bySubscription ? <BaseIcon className={cls + "__subscription-icon"}>crown</BaseIcon> : <div/>}
          <span className={cls + "__name pr-4"} onClick={onClick}>{categoryName}</span>
        </div>
        <span className={cls + "__actions"}>
        <div className={cls + "__question-icon-wrapper d-center"} onClick={onClick}>
          <BaseIcon className={cls + "__question-icon"}>back</BaseIcon>
        </div>
          <div onClick={e => e.stopPropagation()}>
          <div className={cls + "__select-button " +
            (selectedCount === images.length ? "__all" : "")} onClick={selectAll}>
            <div className={cls + "__select-number"}>{selectedCount}</div>
          </div>
          </div>
        </span>
      </div>
      <div className={cls + "__images"} ref={ref}>
        {images.map(image => <GameImage key={image.id} image={image} disableSelect={false} handleSelect={handleSelect}/>)}
      </div>
    </div>
  );
}
