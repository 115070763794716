import { Redirect, useLocation } from "react-router-dom";
import React, { useMemo } from "react";

export default function LinkedModalPageView({ path }: { path: string }) {
  const location = useLocation();
  const to = useMemo(
    () => ({
      ...location,
      pathname: path,
      state: {
        location: {
          pathname: "/",
        },
      },
    }),
    [location, path]
  );
  return <Redirect to={to} />;
}
