import React, { useRef } from "react";
import { useMount, useUnmount } from "react-use";
import InputMask from "inputmask";
import { BaseInputField } from "../../types";
import BaseIcon from "../BaseIcon";
import BaseTooltip from "../BaseTooltip";
import useMobileBlurOnOrientationChanged from "../../hooks/useMobileBlurOnOrientationChanged";
import useMobileKeyboardOpen from "../../hooks/useMobileKeyboard";
import "./style.scss";

export interface Props extends BaseInputField {
  value: string;
  onChange: (value: string) => void;
}

export default function BaseInput({
  name,
  type,
  placeholder,
  errorMessage,
  successMessage,
  label,
  value,
  onChange,
  iconLeft,
  iconRight,
  iconRightClassName,
  iconLeftClassName,
  iconLeftTooltip,
  iconRightTooltip,
  onClickIconLeft,
  onClickIconRight,
  textAlign = "left",
  largeText,
  disabled,
  tooltipText,
  className,
  style,
  labelClassName,
  autofocus,
  autocomplete,
  blurBy,
  readOnly,
  refInput,
  maskOptions,
}: Props) {
  const { setKeyboardOpen } = useMobileKeyboardOpen();
  const htmlFor = `${name}_${new Date().getTime()}`;
  const isError: boolean = (errorMessage && errorMessage.trim() !== "") || false;
  const isSuccess: boolean = (successMessage && successMessage.trim() !== "") || false;

  const cls = ["base-input"];
  if (isError) {
    cls.push("__error");
  } else if (isSuccess) {
    cls.push("__success");
  }
  if (iconLeft) cls.push("__with-left-icon");
  if (iconRight) cls.push("__with-right-icon");
  if ((iconLeft || iconRight) && textAlign === "center") cls.push("__with-icons");
  if (largeText) cls.push("__text-large");
  if (disabled) cls.push("__disabled");
  if (className) cls.push(className);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange(value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (blurBy && event.key === blurBy) {
      event.currentTarget.blur();
    }
  };

  const onFocus = () => {
    setKeyboardOpen(true);
  };

  const onBlur = () => {
    setKeyboardOpen(false);
  };

  const refLocal = useRef<HTMLInputElement | null>(null);
  const instanceInputMask = useRef<InputMask.Instance | null>(null);
  useMount(() => {
    if (maskOptions) {
      const input = refInput?.current || refLocal.current;
      if (input) {
        instanceInputMask.current = new InputMask(maskOptions).mask(input);
      }
    }
  });

  useUnmount(() => {
    if (maskOptions) {
      if (instanceInputMask.current) {
        instanceInputMask.current.remove();
      }
    }
  });

  useMobileBlurOnOrientationChanged(refInput || refLocal);

  return (
    <div className={cls.join(" ")} style={style}>
      {(label || tooltipText) && (
        <div className="base-input__top-wrapper">
          {label && (
            <label className={"base-input__label " + (labelClassName ? labelClassName : "")} htmlFor={htmlFor}>
              {label}
            </label>
          )}
          {tooltipText && <BaseTooltip>{tooltipText}</BaseTooltip>}
        </div>
      )}

      <div className="base-input__input-wrapper">
        {iconLeft && (
          <div
            className={
              "base-input__icon-left" +
              (onClickIconLeft ? " __clickable" : "") +
              (iconLeftClassName ? " " + iconLeftClassName : "")
            }
            onClick={onClickIconLeft}
            title={iconLeftTooltip}
          >
            <BaseIcon>{iconLeft}</BaseIcon>
          </div>
        )}
        <input
          ref={refInput || refLocal}
          readOnly={readOnly}
          style={{ textAlign: textAlign }}
          id={htmlFor}
          className="base-input__input"
          type={type}
          name={name}
          placeholder={placeholder}
          value={value ? value : ""}
          onChange={onChangeHandler}
          disabled={disabled}
          autoFocus={autofocus}
          autoComplete={autocomplete}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {iconRight && (
          <div
            className={
              "base-input__icon-right" +
              (onClickIconRight ? " __clickable" : "") +
              (iconRightClassName ? " " + iconRightClassName : "")
            }
            onClick={onClickIconRight}
            title={iconRightTooltip}
          >
            <BaseIcon>{iconRight}</BaseIcon>
          </div>
        )}
      </div>
      {(isError || isSuccess) && (
        <div style={{ textAlign: textAlign }} className={"base-input__hint" + (isError ? " __error" : " __success")}>
          {errorMessage || successMessage}
        </div>
      )}
    </div>
  );
}
