import React, {useCallback, useEffect, useState} from "react";
import "./style.scss";
import GameCard from "./components/GameCard";
import {Game, LessonGameSettings, LocationState} from "../../types";
import {GameSettings} from "../Games";
import PageGamePlay from "../PageGamePlay";
import BasePage from "../BasePage";
import {Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import {GAME_TYPES} from "../../const";

export interface Props {
  title?: string;
  exitPath?: string;
  planId?: string;
  onAddToPlan?: (game: LessonGameSettings) => void;
}

function PageNewGame({ title, exitPath, onAddToPlan }: Props) {
  const match = useRouteMatch();
  const history = useHistory<LocationState>();

  const [ gameType, setGameType ] = useState<Game | undefined>();
  const [ gameSettings, setGameSettings ] = useState<LessonGameSettings | undefined>();
  const [ selectedGameByPath, setSelectedGameByPath ] = useState<Game | undefined>();

  useEffect(() => {
      const found = window.location.pathname.match('\/games\/(.*)$');
      if (found && found.length > 0) {
        setSelectedGameByPath(GAME_TYPES.find(g => g.type.toLowerCase() === found[1].replaceAll('-', '_')));
      }
  }, []);

  useEffect(() => {
    if(gameSettings) {
      history.push(`${match.url}/play`);
      document.body.scrollIntoView();
    }
  }, [gameSettings]);

  const onClickBackButton = useCallback(() => {
    setGameSettings(gameSettings);
    setGameType(gameType);
    history.push(exitPath ?? '/games');
    document.body.scrollIntoView();
  }, [history]);

  const onGameTypeSelected = useCallback((type) => {
    setGameType(type);
    history.push(`${match.url}/settings`);
    document.body.scrollTo(0, 0);
  }, [history]);

  const onClickSettingsButton = useCallback(() => {
    document.body.scrollTo(0, 0);
    history.push(`${match.url}/settings`);
  }, [history]);

  const onClickAddToPlanButton = () => {
    if (onAddToPlan) {
      onAddToPlan(gameSettings!);
    }
  }

  const prepend = (
    <>
      <div className="page-new-game__header-wrapper">
        {
          <BaseButton
            className="page-new-game__back-button mr-2"
            round
            borderless
            onClick={onClickBackButton}
          >
            <BaseIcon className="mx-n1">back</BaseIcon>
          </BaseButton>
        }
        <div className="page-new-game__header text-left">{title ?? gameType?.name ?? ""}</div>
      </div>
    </>
  );

  return (
      <Switch>
        <Route exact={true} path={`${match.url}/play`}>
          {!gameSettings ? <Redirect to={`${match.url}`}/> :
            <PageGamePlay
              game={gameSettings}
              title={title ?? gameType?.name}
              onClickBackButton={onClickBackButton}
              onClickSettingsButton={onClickSettingsButton}
              addToPlanButton={onAddToPlan ? onClickAddToPlanButton : undefined}
            />
          }
        </Route>
        <Route path={`${match.url}/settings`}>
          {!gameType ? <Redirect to={`${match.url}`}/> :
            <BasePage className="page-new-game" prepend={prepend}>
              <GameSettings type={gameType.type} setGameSettings={setGameSettings}/>
            </BasePage>
          }
        </Route>
        <Route path={`${match.url}`}>
          <BasePage className="page-new-game" prepend={title ? prepend : ""}>
            {/*<div className="game-list">*/}
              {
                selectedGameByPath ?
                  <div>
                    <GameCard key={selectedGameByPath.path} game={selectedGameByPath}
                              onGameTypeSelected={onGameTypeSelected} selectedByPath={true}/>
                    <h1 className="page-new-game__another-games-title">Другие игры</h1>
                  </div>
                  : <div/>
              }
              {GAME_TYPES
                .filter(g => g !== selectedGameByPath)
                .map((game) => {
                  return <GameCard key={game.path} game={game} onGameTypeSelected={onGameTypeSelected}/>;
                })
              }
            {/*</div>*/}
          </BasePage>
        </Route>
      </Switch>
  );
}

export default PageNewGame;
