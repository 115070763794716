import React, { useState } from "react";
import BaseInput, { Props } from "../BaseInput";

export default function InputPassword(props: Props) {
  const [iconRight, setIcon] = useState<"show-off" | "show-on">("show-off");
  const [type, setType] = useState<"password" | "text">("password");
  const onClickIconRight = () => {
    if (type === "password") {
      setIcon("show-on");
      setType("text");
      return;
    }
    setIcon("show-off");
    setType("password");
  };
  const inputProps: Props = {
    ...props,
    type,
    iconRight,
    onClickIconRight,
  };
  return <BaseInput {...inputProps} />;
}
