import React from "react";
import "./style.scss";
import TicTacToeCardImage, {TicTacToeCardItem} from "../TicTacToeCardImage";


interface Props {
  images: TicTacToeCardItem[];
  gridSize: number;
  moveItem: (i: any, cardId: number) => void;
}

export default function TicTacToeCard({ images, gridSize, moveItem }: Props) {

  return (
    <div className="tictactoe-card" style={{
      gridTemplateColumns: "1fr ".repeat(gridSize),
      // maxWidth: images.length / 3 === 3 ? "330px" : "500px"
    }}>
      {images.map(i => (
        <TicTacToeCardImage key={i.id}
                            cardItem={i}
                            moveItem={moveItem}
        />
      ))}
    </div>);
}
