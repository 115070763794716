import useAppState from "./useAppState";

const useMobileKeyboardOpen = () => {
  const { keyboardOpen, dispatch } = useAppState();

  const setKeyboardOpen = (flag: boolean) => dispatch({ type: "setKeyboardOpen", payload: flag });
  return {
    keyboardOpen,
    setKeyboardOpen,
  };
};

export default useMobileKeyboardOpen;
