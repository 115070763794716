import React from "react";
import {useViewController} from "./viewController";
import "./style.scss";
import {useTranslation} from "../../locales";
import useModalLinkPrinter from "../../hooks/useModalLinkPrinter";
import ContentHeader from "../../components/ContentHeader";
import BaseButton from "../../components/BaseButton";
import BaseForm from "../../components/BaseForm";
import ModalWindow from "../../components/ModalWindow";

interface Props {
  error?: string;
  show: boolean;
  onClose: () => void;
}

function PromoCodeActivation({ show, onClose }: Props) {
  const { fields, values, setValues, errors, sendActivate, onChange, setErrors } = useViewController();
  const { t } = useTranslation();
  const { notification } = useModalLinkPrinter();

  const onActivated = () => handleClose();
  const handleClose = () => {
    onClose();
    setValues({});
    setErrors({});
  }

  return (
    <ModalWindow show={show} onClose={handleClose} className={"promo-code-activation-modal"}>
      <BaseForm
        className="promo-code-activation"
        autofocus={true}
        fields={fields}
        values={values}
        errors={errors}
        notification={notification}
        onChange={onChange}
        setErrors={setErrors}
        headline={<ContentHeader>{t("Активация промокода")}</ContentHeader>}
        onSubmitEnter={() => sendActivate(onActivated)}
      >
        <>
          <div className="promo-code-activation__control-wrapper __buttons">
            <BaseButton className="promo-code-activation__activate" name="submit" large={true} fluid={true} onClick={() => sendActivate(onActivated)}>
              {t("Активировать")}
            </BaseButton>
          </div>
          <div className={"promo-code-activation__offer"}>
            Вы можете найти промокод <a target="_blank" href="https://vk.com/teacherskaya_platform">в нашей группе</a> в блоке "Получить промокод"!
          </div>
        </>
      </BaseForm>
    </ModalWindow>
  );
}

export default PromoCodeActivation;
