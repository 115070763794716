import React from "react";
import {useViewController} from "./viewController";
import "./style.scss";
import {useTranslation} from "../../../../locales";
import useModalLinkPrinter from "../../../../hooks/useModalLinkPrinter";
import BaseForm from "../../../../components/BaseForm";
import ContentHeader from "../../../../components/ContentHeader";
import BaseButton from "../../../../components/BaseButton";

interface Props {
  error?: string;
  onCreated: () => void;
}

function LessonPlanCreate({ error, onCreated }: Props) {
  const { fields, values, errors, onCreate, onChange, setErrors } = useViewController();
  const { t } = useTranslation();
  const { getLocation, notification } = useModalLinkPrinter();

  return (
    <>
      <BaseForm
        className="lesson-plan-create"
        autofocus={true}
        fields={fields}
        values={values}
        errors={errors}
        notification={notification}
        onChange={onChange}
        setErrors={setErrors}
        headline={<ContentHeader>{t("Создание плана")}</ContentHeader>}
        onSubmitEnter={() => onCreate(onCreated)}
      >
        <>
          <div className="lesson-plan-create__control-wrapper __buttons">
            <BaseButton className="lesson-plan-create__create" name="submit" large={true} fluid={true} onClick={() => onCreate(onCreated)}>
              {t("Создать")}
            </BaseButton>
          </div>
        </>
      </BaseForm>
    </>
  );
}

export default LessonPlanCreate;
