import { useState } from "react";
import { setSocialLoginStatus } from "../../lib/authHelper";
import google from "../../lib/google";
import twitter from "../../lib/twitter";
import facebook from "../../lib/facebook";
import providers from "../../lib/providers";
import config from "../../config";

function useController(onClose: () => void) {
  const [isLoading, setIsLoading] = useState(false);

  const googleLogin = () => {
    google
      .login()
      .then(async (response) => {
        if (!response || !response.code) return;
        setSocialLoginStatus(true);
        setIsLoading(true);
        await providers.LoginProvider.googleLogin({
          data: { role: config.userJoinRole, token: response.code },
        });
        onClose();
      })
      .catch((e) => {
        setSocialLoginStatus(false);
        setIsLoading(false);
        throw e;
      });
  };
  const twLogin = () => {
    twitter
      .login()
      .then(async (response) => {
        if (response) {
          setSocialLoginStatus(true);
          setIsLoading(true);
          await providers.LoginProvider.twitterLogin({
            data: { ...response, role: config.userJoinRole },
          });
          onClose();
        }
      })
      .catch((e) => {
        setSocialLoginStatus(false);
        setIsLoading(false);
        throw e;
      });
  };

  const fbLogin = () => {
    facebook
      .login()
      .then(async (token) => {
        if (!token || !token.authResponse) return;
        setSocialLoginStatus(true);
        setIsLoading(true);
        await providers.LoginProvider.facebookLogin({
          data: { role: config.userJoinRole, token: token.authResponse.accessToken },
        });
        onClose();
      })
      .catch((e) => {
        setSocialLoginStatus(false);
        setIsLoading(false);
        throw e;
      });
  };

  return {
    googleLogin,
    twLogin,
    fbLogin,
    isLoading,
  };
}

export default useController;
