import { useController } from "./controller";
import { useEffect, useState } from "react";
import useAuthUser from "../../hooks/useAuthUser";

function useViewController(onClose: () => void, code?: string | null) {
  const { onResendCode, onSendCode, onCancel, verifyByLongCode } = useController(onClose);
  const { user } = useAuthUser();
  const [status, setStatus] = useState<"pending" | "success" | "failed">("pending");

  useEffect(() => {
    if (code && (!user || !user.emailVerified)) {
      verifyByLongCode(code).then((status) => {
        setStatus(status ? "success" : "failed");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    email: user?.email ?? "",
    onResendCode,
    onSendCode,
    onCancel,
    status,
  };
}

export default useViewController;
