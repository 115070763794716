import {LessonGameType} from "@teacherskaya/api-provider/dist/api/LessonGameProvider";
import {LessonGameSettings} from "../../types";
import MemoryGameSettings from "./MemoryGame/components/MemoryGameSettings";
import React from "react";
import ShadowGameSettings from "./ShadowGame/components/ShadowGameSettings";
import LottoGameSettings from "./LottoGame/components/LottoGameSettings";
import DobbleGameSettings from "./DobbleGame/components/DobbleGameSettings";
import MemoryGamePlay from "./MemoryGame/components/MemoryGamePlay";
import ShadowGamePlay from "./ShadowGame/components/ShadowGamePlay";
import LottoGamePlay from "./LottoGame/components/LottoGamePlay";
import DobbleGamePlay from "./DobbleGame/components/DobbleGamePlay";
import VocabularyIntroductionGameSettings
  from "./VocabularyIntroductionGame/components/VocabularyIntroductionGameSettings";
import VocabularyIntroductionGamePlay from "./VocabularyIntroductionGame/components/VocabularyIntroductionGamePlay";
import TicTacToeGameSettings from "./TicTacToeGame/components/TicTacToeGameSettings";
import TicTacToeGamePlay from "./TicTacToeGame/components/TicTacToeGamePlay";
import HiddenNumberGameSettings from "./HiddenNumberGame/components/HiddenNumberGameSettings";
import HiddenNumberGamePlay from "./HiddenNumberGame/components/HiddenNumberGamePlay";

interface SettingsProps {
  type: LessonGameType;
  setGameSettings: (game: LessonGameSettings) => void;
}

export function GameSettings({type, setGameSettings}: SettingsProps) {
  if (type === "MEMORY") {
    return (<MemoryGameSettings onGameConfigured={setGameSettings}/>);
  } else if (type === "SHADOW") {
    return (<ShadowGameSettings onGameConfigured={setGameSettings}/>);
  } else if (type === "LOTTO") {
    return (<LottoGameSettings onGameConfigured={setGameSettings}/>);
  } else if (type === "DOBBLE") {
    return (<DobbleGameSettings onGameConfigured={setGameSettings}/>);
  } else if (type === "VOCABULARY_INTRODUCTION") {
    return (<VocabularyIntroductionGameSettings onGameConfigured={setGameSettings}/>);
  } else if (type === "TIC_TAC_TOE") {
    return (<TicTacToeGameSettings onGameConfigured={setGameSettings}/>);
  } else if (type === "HIDDEN_NUMBER") {
    return (<HiddenNumberGameSettings onGameConfigured={setGameSettings}/>);
  } else {
    throw new Error("Unknown game type");
  }
}

interface PlayProps {
  game: LessonGameSettings;
}

export function GamePlay({game}: PlayProps) {
  if (game.type === "MEMORY") {
    //принимать game, поменять тип images
    return (<MemoryGamePlay images={game.images}/>);
  } else if (game.type === "SHADOW") {
    return (<ShadowGamePlay images={game.images}/>);
  } else if (game.type === "LOTTO") {
    return (<LottoGamePlay cardNumber={game.cardCount!} imagesOnCard={game.imageOnCardCount!} images={game.images}/>);
  } else if (game.type === "DOBBLE") {
    return (<DobbleGamePlay imagesOnCard={game.imageOnCardCount!} images={game.images}/>);
  } else if (game.type === "VOCABULARY_INTRODUCTION") {
    return (<VocabularyIntroductionGamePlay images={game.images}/>);
  } else if (game.type === "TIC_TAC_TOE") {
    return (<TicTacToeGamePlay images={game.images}/>);
  } else if (game.type === "HIDDEN_NUMBER") {
    return (<HiddenNumberGamePlay numberOfNumbers={game.cardCount!}/>);
  } else {
    throw new Error("Unknown game type");
  }
}
