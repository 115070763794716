import providers from "../../../../lib/providers";

export function useController() {
  async function onDelete(gameId: string) {
    return await providers.LessonGameProvider.delete({ id: gameId });
  }

  return {
    onDelete,
  };
}
