import React from "react";
import "./style.scss";
import { useTranslation } from "../../../../locales";
import BaseButton from "../../../../components/BaseButton";
import ActionStatus from "../../../../components/ActionStatus";
import useAuthUser from "../../../../hooks/useAuthUser";

function ConfirmationLongCode({ status }: { status: "pending" | "success" | "failed" }) {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const showLoginButton = !user;

  let headerText = "Загрузка...";
  let messageText = "";

  if (status === "success") {
    headerText = "Почта подтверждена";
    messageText = !showLoginButton ? "Пожалуйста, пройдите в форму входа" : "";
  } else if (status === "failed") {
    headerText = "Почта не подтверждена";
    messageText = "Неверный код подтверждения";
  }

  return (
    <ActionStatus title={t(headerText)} description={t(messageText)} status={status} className="notification-window">
      {status === "failed" ? (
        <BaseButton className="notification-window__button" large={true} to={"/signup/confirm"}>
          {t("Попробовать еще раз")}
        </BaseButton>
      ) : showLoginButton ? (
        <BaseButton className="notification-window__button" large={true} to={"/login"}>
          {t("Войти")}
        </BaseButton>
      ) : null}
    </ActionStatus>
  );
}

export default ConfirmationLongCode;
