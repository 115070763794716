import { NavigationMenuItemInterface } from "../../../../types";

function useViewController() {
  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: "Профиль",
      name: "about",
      to: "/user/settings",
    },
  ];

  return { topNavigationMenu, showNavMenu: false };
}

export default useViewController;
