import React from "react";
import {t} from "../../../../locales";
import "./style.scss";
import config from "../../../../config";
import LandingBackground from "../LandingBackground";

export default function BlockSiteVideo() {
  return (
    <div className="block-site-video">
      <LandingBackground className="block-site-video__background" />
      <div className="block-site-video__container">
        <div className="block-site-video__text">
          <h2 className="block-site-video__title">{t("Как работать с платформой")}</h2>
          <ol className="block-site-video__list">
            <li>регистрируйтесь</li>
            <li>создавайте игры
              <ul className="block-site-video__list__list">
                <li>можно настроить игру и тут же в нее сыграть</li>
                <li>можно создать план урока и сохранить в него настроенные игры</li>
              </ul>
            </li>
            <li>для расширенного доступа покупайте подписку или вводите промокод <a target="_blank" href="https://vk.com/teacherskaya_platform">из группы ВК</a></li>
          </ol>
        </div>
        <video className="block-site-video__video"
               src={config.s3Url + "/public/how-to-work-with-platform.mp4"}
               poster={config.s3Url + "/public/landing_video_preview.jpg"}
               controls
        />
      </div>
    </div>
  );
}
