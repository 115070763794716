import { useEffect, useRef } from "react";

export default function useMounted() {
  const status = useRef(true);

  useEffect(
    () => () => {
      status.current = false;
    },
    []
  );

  return status;
}
