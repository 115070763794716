import React, { createContext, Reducer, useContext, useEffect, useReducer } from "react";
import { SystemSettings } from "@teacherskaya/api-provider/dist/api/SystemSettingsProvider";
import { isMobile } from "@teacherskaya/tools/dist/lib/browserUtils";
import providers from "../lib/providers";

type Action =
  | {
      type: "setSystemSettings";
      payload: SystemSettings;
    }
  | {
      type: "setKeyboardOpen";
      payload: boolean;
    };

interface AppState {
  systemSettings: SystemSettings | null;
  keyboardOpen: boolean;
}

const reducer: Reducer<AppState, Action> = (state, action) => {
  switch (action.type) {
    case "setSystemSettings":
      return { ...state, systemSettings: action.payload };
    case "setKeyboardOpen":
      return { ...state, keyboardOpen: action.payload };
  }
};

const defaultState: AppState = {
  systemSettings: null,
  keyboardOpen: false,
};

const AppStateContext = createContext<[AppState, React.Dispatch<Action>]>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

function useSystemSettings(dispatch: React.Dispatch<Action>) {
  useEffect(() => {
    (async function () {
      const systemSettings = await providers.SystemSettingsProvider.get();
      dispatch({ type: "setSystemSettings", payload: systemSettings });
    })();
  }, [dispatch]);
}

function useKeyboardOpen(keyboardOpen: boolean) {
  useEffect(() => {
    if (keyboardOpen && isMobile()) {
      document.body.classList.add("keyboard-open");
    } else {
      document.body.classList.remove("keyboard-open");
    }
  }, [keyboardOpen]);
}

export function AppStateContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  useKeyboardOpen(state.keyboardOpen);
  useSystemSettings(dispatch);
  return React.createElement(
    AppStateContext.Provider,
    {
      value: [state, dispatch],
    },
    props.children
  );
}

export default function useAppState() {
  const [{ systemSettings, keyboardOpen }, dispatch] = useContext(AppStateContext);
  return {
    systemSettings,
    keyboardOpen,
    dispatch,
  };
}
