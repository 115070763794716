import React, {useEffect, useState} from "react";
import "./style.scss";
import HiddenNumberCard from "../HiddenNumberCard";
import HiddenSelectedNumber from "../HiddenSelectedNumber";
import HiddenAllSelectedNumber from "../HiddenAllSelectedNumber";
import ModalWindow from "../../../../ModalWindow";
import BaseButton from "../../../../BaseButton";

interface Props {
  numberOfNumbers: number;
}

export enum InequalitySign {
  EQUAL = "=",
  LESS= "⬇",
  MORE= "⬆",
}

export interface HiddenNumberItem {
  id: number;
  selected: boolean;
  disabled: boolean;
}

export interface SelectedNumber {
  id: number;
  inequalitySign: InequalitySign;
}

export interface ErrorModal {
  number: number;
  wrongSign: InequalitySign;
  selectedNumber: SelectedNumber;
}

export default function HiddenNumberGamePlay({ numberOfNumbers }: Props) {
  const [numberItems, setNumberItems] = useState<HiddenNumberItem[]>([]);
  const [currentSelected, setCurrentSelected] = useState<number | undefined>(undefined);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedNumbers, setSelectedNumbers] = useState<SelectedNumber[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<ErrorModal | undefined>(undefined);

  const onErrorModalStart = (error: ErrorModal) => {
    setShowErrorModal(error);
  };

  const onErrorModalClose = () => {
    setShowErrorModal(undefined);
  };

  const resetNumberItems = () => {
    setNumberItems(Array.from(Array(numberOfNumbers).keys()).map(i => {
      return {
        id: ++i,
        selected: false,
        disabled: false
      };
    }));
    setCurrentSelected(undefined);
    setSelectedNumbers([]);
    setDisabled(false);
  }
  useEffect(() => resetNumberItems(), []);
  
  const handleUnselectedItemClick = (item: HiddenNumberItem) => {
    if (item.selected || item.disabled || currentSelected) {
      return;
    }
    setNumberItems(numberItems.map(i => i.id !== item.id ? i : {
      ...i,
      selected: true
    }));
    setCurrentSelected(item.id);
    if (numberItems.filter(n => n.id !== item.id).every(n => n.disabled || n.selected)) {
      setDisabled(true);
    }
  }

  const handleSelectedItemClick = (id: number | undefined, sign: InequalitySign) => {
    if (!id) return;

    //Если выбрали "равно"
    if (sign === InequalitySign.EQUAL) {
      setDisabled(true);
      return;
    }

    //Если выбрали самое маленькое и большое число
    if (id === 1 && sign === InequalitySign.LESS) return;
    if (id === numberItems.length && sign === InequalitySign.MORE) return;


    //Проверка, что соседние числа не имеют другой знак
    for (const n of selectedNumbers) {
      if (n.inequalitySign === sign) {
        continue;
      }
      if ((Math.abs(n.id - id) <= 1)) {
        onErrorModalStart({number: id, wrongSign: sign, selectedNumber: {
            id: n.id,
            inequalitySign: n.inequalitySign
          }});
        return;
      }
    }

    //Добавляем число в выбранные
    selectedNumbers.push({
      id: id,
      inequalitySign: sign
    });
    setSelectedNumbers(selectedNumbers);

    //Запрещаем выбрать числа больше или меньше в зависимости от знака
    for (let n of numberItems) {
      if (sign === InequalitySign.MORE) {
        if (n.id < id) {
          n.disabled = true;
        }
      } else {
        if (n.id > id) {
          n.disabled = true;
        }
      }
    }
    setNumberItems(numberItems);

    //Разрешаем выбрать новое число
    setCurrentSelected(undefined);
  }

  return (
    <div>
      <div className={"hidden-number"}>
        <div className={"hidden-number__selected"}>
          <div className={"hidden-number__selected__current-number"}>
            <HiddenSelectedNumber
              number={currentSelected}
              handleClick={handleSelectedItemClick}
              disabled={disabled}
            />
          </div>
          <div className={"hidden-number__selected__all-numbers"}>
            <HiddenAllSelectedNumber numbers={selectedNumbers}/>
          </div>
        </div>
        <div className={"hidden-number__card"}>
          <HiddenNumberCard numbers={numberItems} handleClick={handleUnselectedItemClick}/>
        </div>
        <ModalWindow className={"hidden-number__error"} show={showErrorModal !== undefined} onClose={onErrorModalClose}>
          <h2 className="hidden-number__error__text">
            {
              "Загаданное число не может быть "
              + (showErrorModal?.wrongSign === InequalitySign.MORE ? "больше" : "меньше") + " " + showErrorModal?.number
              + ", потому что ранее указали, что загаданное число "
              + (showErrorModal?.selectedNumber.inequalitySign === InequalitySign.MORE ? "больше" : "меньше")
              + " " + showErrorModal?.selectedNumber.id
            }
          </h2>
          <BaseButton large={true} fluid={true} onClick={onErrorModalClose}
                      className="hidden-number__error__close-button">{"Понятно"}</BaseButton>
        </ModalWindow>
      </div>
    </div>
  );
}
