import React from "react";
import BasePage from "../../components/BasePage";
import BlockAbout from "./components/BlockAbout";
import BlockStartGame from "./components/BlockStartGame";
import "./style.scss";
import BlockSiteVideo from "./components/BlockSiteVideo";
import BlockGames from "./components/BlockGames";

export default function PageLanding() {
  return (
    <BasePage className="page-landing">
      <BlockAbout />
      <BlockSiteVideo/>
      {/*<BlockWhyWe />*/}
      <BlockGames />
      {/*<BlockHowItWorks />*/}
      <BlockStartGame />
    </BasePage>
  );
}
