export default function getSeries(N: number): number[][] {
  let progressiveNumber = 0;    // progressive number of cards
  const result = []; // array of series (cards)

  // Generate series from #01 to #N
  for (let i = 0; i <= N - 1; i++) {
    let s = [];
    progressiveNumber++;
    s.push(1);
    for (let i2 = 1; i2 <= N - 1; i2++) {
      s.push((N - 1) + (N - 1) * (i - 1) + (i2 + 1));
    }
    result.push(s.sort(() => 0.5 - Math.random()));
  }

  // Generate series from #N+1 to #N+(N-1)*(N-1)
  for (let i = 1; i <= N - 1; i++) {
    for (let i2 = 1; i2 <= N - 1; i2++) {
      let s = [];
      progressiveNumber++;
      s.push(i + 1);
      for (let i3 = 1; i3 <= N - 1; i3++) {
        s.push((N + 1) + (N - 1) * (i3 - 1) + (((i - 1) * (i3 - 1) + (i2 - 1))) % (N - 1));
      }
      result.push(s.sort(() => 0.5 - Math.random()));
    }
  }

  return result;
}

export function getCombinations(length: number): number[][] {
  const result: number[][] = []; // array of series (cards)

  for (let i = 0; i < length; i++) {
    for (let i2 = i + 1; i2 < length; i2++) {
      let s = [];
      s.push(i);
      s.push(i2);
      result.push(s);
    }
  }

  result.sort(() => 0.5 - Math.random());

  return result;
}