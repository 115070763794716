import providers from "../../../../lib/providers";

export function useController() {
  async function onDelete(planId: string) {
    return await providers.LessonPlanProvider.delete({ id: planId });
  }

  return {
    onDelete,
  };
}
