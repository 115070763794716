/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useMemo} from "react";
import {Redirect, Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";
import {LocationState} from "../../types";
import PageLoginTwitter from "../../pages/PageLoginTwitter";
import PageSettings from "../../pages/PageUserArea";
import PageLogout from "../../pages/PageLogout";
import PageLoginToken from "../../pages/PageLoginToken";
import ForgotPassword from "../../modals/ForgotPassword";
import ClientLogin from "../../modals/ClientLogin";
import ClientSignUp from "../../modals/ClientSignUp";
import EmailVerificationByCode from "../../modals/EmailVerificationByCode";
import ConfirmEmail from "../../modals/ConfirmEmail";
import ModalWindow from "../../components/ModalWindow";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import LinkedModalPageView from "../LinkedModalPageView";
import PageLanding from "../../pages/PageLanding";
import PageFAQ from "../../pages/PageFAQ";
import PageAboutUs from "../../pages/PageAboutUs";
import useAuthUser from "../../hooks/useAuthUser";
import PageLessonPlans from "../../pages/PageLessonPlans";
import PageLessonGames from "../../pages/PageLessonGames";
import LessonGameCreate from "../../pages/PageLessonGames/components/LessonGameCreate";
import PageNewGame from "../../components/PageNewGame";
import LessonGamePlay from "../../pages/PageLessonGames/components/LessonGamePlay";
import Subscription from "../../modals/Subscription";
import {HISTORY_ACTIONS} from "../../const";

interface LinkedModalDescription {
  path: string;
  Component: React.FC;
  meta?: {
    authOnly?: boolean;
    modal?: boolean;
  };
}

interface RouterDescription {
  path: string;
  exact?: boolean;
  Component: React.FC;
}

export default function MainRouterView() {
  const location = useLocation<LocationState>();
  const history = useHistory<LocationState>();
  const { user } = useAuthUser();

  useEffect(() => {
    if (history.action !== HISTORY_ACTIONS.POP) {
      document.body.scrollIntoView();
    }
  }, [history.action, history.location]);

  const modals: LinkedModalDescription[] = [
    {
      path: "/login",
      Component: () => <ClientLogin onClose={onModalClose} />,
    },
    {
      path: "/login/forgot",
      Component: () => <ForgotPassword onCancel={onModalClose} />,
    },
    {
      path: "/login/forgot/:email",
      Component: () => {
        const { email } = useParams<{ email: string }>();
        return <ForgotPassword email={email} onCancel={onModalClose} />;
      },
    },
    {
      path: "/login/forgot/:email/:token",
      Component: () => {
        const { token } = useParams<{ token: string }>();
        return <ForgotPassword token={token} onCancel={onModalClose} />;
      },
    },
    {
      path: "/signup",
      Component: () => <ClientSignUp onClose={onModalClose} />,
    },
    {
      path: "/signup/email",
      Component: () => <ConfirmEmail onCancel={onModalClose} />,
      meta: {
        modal: location.pathname === "/signup/email",
      },
    },
    {
      path: "/signup/confirm",
      Component: () => {
        const search = new URLSearchParams(location.search);
        const code = search.get("code");
        return <EmailVerificationByCode onClose={onModalClose} code={code} />;
      },
      meta: {
        modal: location.pathname === "/signup/confirm",
      },
    },
    {
      path: "/subscription",
      Component: () => <Subscription />
    },
  ];


  const routes = useMemo(() => {
    const routes: RouterDescription[] = [];

    routes.push(
      {
        path: "/games",
        Component: () => <PageNewGame />,
      },
      {
        path: "/plans/:planId/new-game",
        Component: () => {
          const { planId } = useParams<{ planId: string }>();
          return <LessonGameCreate planId={planId}/>;
        },
      },
      {
        path: "/plans/:planId/games/:gameId",
        Component: () => {
          const { planId, gameId } = useParams<{ planId: string, gameId: string }>();
          return <LessonGamePlay planId={planId} gameId={gameId}/>;
        },
      },
      {
        path: "/plans/:planId",
        Component: () => {
          const { planId } = useParams<{ planId: string }>();
          return <PageLessonGames planId={planId}/>;
        },
      },
      {
        path: "/plans",
        Component: () => <PageLessonPlans />,
      },
      {
        path: "/login/token/:token",
        Component: () => {
          const { token } = useParams<{ token: string }>();
          return <PageLoginToken token={ token }/>;
        },
      },
      {
        path: "/faq",
        Component: () => <PageFAQ />,
      },
      {
        path: "/about-us",
        Component: () => <PageAboutUs />,
      },
    );

    return routes;
  }, []);

  const currentModal = modals.find((m) => m.path === location.pathname);

  const backgroundLocation = location.state?.location;
  const onModalClose = useCallback(() => backgroundLocation && history.replace(backgroundLocation), [
    backgroundLocation,
    history,
  ]);
  return (
      <>
        <Switch location={backgroundLocation || location}>
          {!backgroundLocation &&
              modals.map((data) => (
                  <Route path={data.path} exact key={data.path}>
                    <LinkedModalPageView path={data.path} />
                  </Route>
              ))}
          <Route path={"/user"}>
            <PageSettings />
          </Route>
          <Route path={"/logout"}>
            <PageLogout />
          </Route>
          <Route path={"/login/twitter"}>
            <PageLoginTwitter />
          </Route>
          {routes.map(({ path, exact, Component }) => (
              <Route path={path} exact={exact} key={path}>
                <ScrollToTopOnMount />
                <Component />
              </Route>
          ))}
          <Route path={"/"}>
            {user !== null && user.emailVerified ? <Redirect to="/games"/> : <PageLanding />}
          </Route>
        </Switch>
        {backgroundLocation && (
            <ModalWindow show={true} modal={currentModal?.meta?.modal} onClose={onModalClose}>
              {modals.map(({ path, Component }) => (
                  <Route path={path} exact key={path}>
                    <Component />
                  </Route>
              ))}
            </ModalWindow>
        )}
      </>
  );
}
