import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import iconsMap from "./svgMap";

library.add(fab, faTimes, fas);

function BaseIcon({
  children,
  onClick,
  className="",
  size,
}: {
  children: string;
  onClick?: () => void;
  className?: string;
  size?: string | number;
}) {
  const cls = ["icon"];
  cls.push(className);
  let icon: IconProp | null = null;
  if (children.includes("fa-")) {
    icon = children.slice(3) as IconProp;
  } else if (children.includes("fas-") || children.includes("fab-")) {
    icon = [children.substring(0, 3), children.slice(4)] as IconProp;
  }
  if (icon) {
    return <FontAwesomeIcon icon={icon} style={{ fontSize: size }} className={cls.join(" ")} onClick={onClick} />;
  }

  const SvgIcon = iconsMap[children];
  return SvgIcon ? (
    <SvgIcon onClick={onClick} style={{ width: size, height: size }} className={cls.join(" ")} />
  ) : null;
}

export default React.memo(BaseIcon);
