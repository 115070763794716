import React, {useEffect, useState} from "react";
import "./style.scss";
import {ImageCard} from "../../../../GameSettings";
import getSeries, {getCombinations} from "./dobble";
import DobbleCard from "../DobbleCard";
import useCacheImagesController from "../../../../../lib/cacheImages";
import BaseSpinner from "../../../../../components/BaseSpinner";


interface Props {
    imagesOnCard: number
    images: ImageCard[];
}

export default function DobbleGamePlay({ imagesOnCard, images }: Props) {
    const [currentCombinationNumber, setCurrentCombinationNumber] = useState<number>(0);
    const [series] = useState<number[][]>(getSeries(imagesOnCard));
    const [combinations] = useState<number[][]>(getCombinations(series.length));
    const [img] = useState<ImageCard[]>(images);
    const {loading, setLoading, cacheImages} = useCacheImagesController();

    useEffect(() => {
        setLoading(true);
        cacheImages([
            ...series[combinations[currentCombinationNumber][0]].map(s => img[s-1]),
            ...series[combinations[currentCombinationNumber][1]].map(s => img[s-1])
        ], true);
        cacheImages(img);
    }, []);

    const nextCombination = () => {
        if(combinations.length - 1 > currentCombinationNumber) {
            setCurrentCombinationNumber(prev => prev + 1);
        }
    }

    const handleImageClick = (image: ImageCard) => {
        const leftImages = series[combinations[currentCombinationNumber][0]].map(s => img[s-1]);
        const rightImages = series[combinations[currentCombinationNumber][1]].map(s => img[s-1]);

        if(leftImages.find(i => i.id === image.id) && rightImages.find(i => i.id === image.id)) {
            nextCombination();
        }
    }

    return (
      <div>
          {loading ? (
              <div className="dobble-game__spinner-container">
                  <BaseSpinner size={45} />
              </div>) :
            <div>
                <div>
                    {
                        <div className="dobble-game__cards">
                            <div className="dobble-game__left-card">
                                <DobbleCard key={currentCombinationNumber + "_left"}
                                            images={series[combinations[currentCombinationNumber][0]].map(s => img[s-1])}
                                            handleImageClick={handleImageClick}
                                />
                            </div>
                            <div className="dobble-game__right-card">
                                <DobbleCard key={currentCombinationNumber + "_right"}
                                            images={series[combinations[currentCombinationNumber][1]].map(s => img[s-1])}
                                            handleImageClick={handleImageClick}
                                />
                            </div>
                        </div>
                    }
                </div>

            </div>}
      </div>
    );
}
