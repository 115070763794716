import providers from "../../../../lib/providers";
import {useEffect, useState} from "react";
import {LessonPlan} from "@teacherskaya/api-provider/dist/api/LessonPlanProvider";
import useAuthUser from "../../../../hooks/useAuthUser";
import {LessonGameSettings} from "../../../../types";
import config from "../../../../config";
import {GameImage} from "@teacherskaya/api-provider/dist/api/GameImageProvider";

export function useController(planId: string, gameId: string) {
  const { user } = useAuthUser();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState<LessonPlan>();
  const [game, setGame] = useState<LessonGameSettings>();

  const getPlan = async () => {
    const response = await providers.LessonPlanProvider.findById({ id: planId });
    const game = response.games?.find(g => g.id === gameId);
    if (!game) return;
    setPlan(response);
    setGame({
      ...game,
      images: game.images.map((i: GameImage) => ({
        id: Number.parseInt(i.id),
        category: i.category,
        name: i.word,
        urlPart: i.urlPart,
        src: config.s3Url + "/" + i.urlPart,
        thumbnailSrc: config.thumbnailUrl + "/" + i.urlPart,
        bySubscription: i.category !== 'Еда',
        selected: false,
      })),
    })};

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    getPlan().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    plan,
    game,
  };
}
