import React, {useCallback} from "react";
import "./style.scss";
import {LessonPlan} from "@teacherskaya/api-provider/dist/api/LessonPlanProvider";
import {getDate} from "../../../../lib/timeHelper";
import BaseIcon from "../../../../components/BaseIcon";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import {useHistory} from "react-router-dom";
import {LocationState} from "../../../../types";

interface Props {
  plan: LessonPlan;
  onRename(planId: string): void;
  onDelete(planId: string): void;
}

function LessonPlanItem({
                          plan,
                          onRename,
                          onDelete,
                        }: Props) {
  const date = getDate(plan.createTime);
  const history = useHistory<LocationState>();
  const { ref, isComponentVisible, toggleComponentVisible: toggleMenu} = useComponentVisible(false);

  const onRenameInner = () => {
    onRename(plan.id);
    toggleMenu();
  }

  const onDeleteInner = () => {
    onDelete(plan.id);
    toggleMenu();
  }

  const onPlanClick = useCallback(() => {
    history.push("/plans/" + plan.id);
  }, [history]);

  return (
    <>
      <li className="lesson-plan-item" onClick={onPlanClick}>
        <div onClick={() => {}} className="lesson-plan-item__name">
          <h3 className="performer-card__name">{plan.name}</h3>
        </div>
        <div className="lesson-plan-item__time">
          <span>{date.dayOfMonth}.{date.month}.{date.year} {date.hours}:{date.minutes}</span>
        </div>
        <div ref={ref} onClick={e => e.stopPropagation()}>
          <BaseIcon onClick={toggleMenu} className="lesson-plan-item__more-icon">
            more
          </BaseIcon>
        {isComponentVisible && (
          <div className="lesson-plan-item__dots-menu">
            <div onClick={onRenameInner} className="lesson-plan-item__dots-menu-element">Переименовать</div>
            <div onClick={onDeleteInner} className="lesson-plan-item__dots-menu-element red">Удалить</div>
          </div>
        )}
        </div>
      </li>
    </>
  )
}

export default LessonPlanItem;