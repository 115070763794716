import { useHistory } from "react-router-dom";

function PageLoginTwitter() {
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const requestToken = params.get("oauth_token");
  const verifier = params.get("oauth_verifier");

  if (requestToken && verifier) {
    (window.opener as typeof window).TWTRAuthHandler({
      requestToken: requestToken || "",
      verifier: verifier || "",
    });
  } else (window.opener as any).TWTRAuthHandler(null);
  window.close();

  return null;
}

export default PageLoginTwitter;
