import React from "react";
import {t} from "../../../../locales";
import "./style.scss";
import GameItem from "../GameItem";
import {GAME_TYPES} from "../../../../const";

export default function BlockCoachingServices() {
  return (
    <div className="block-games">
      <h2 className="block-games__title">{t("Наши игры")}</h2>
      <div className="block-games__container">
        {GAME_TYPES.map((item, index) => {
          return (
            <GameItem
              key={index}
              className="block-games__service-item"
              title={item.name}
              imagePath={item.imgSrc}
              gamePath={item.path}
            >
              {item.briefDescription}
            </GameItem>
          );
        })}
      </div>
    </div>
  );
}
