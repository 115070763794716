import React from "react";
import "./style.scss";

interface Props {
  children: JSX.Element | string;
  className?: string;
}

function ContentHeader({ children, className }: Props) {
  const classes = (className && className.split(" ")) || [];
  classes.push("content-header");
  return <h2 className={classes.join(" ")}>{children}</h2>;
}

export default ContentHeader;
