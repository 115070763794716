import React from "react";
import "./styles.scss";

interface Props {
  className?: string;
}

export default function LandingBackground({ className }: Props) {
  const cls = ["landing-background"];
  if (className) cls.push(className);

  return <div className={cls.join(" ")} />;
}
