/* eslint-disable @typescript-eslint/camelcase */
export default {
  Credit: "Credit",
  Credit_plural: "Credits",
  NewMessage: "New message",
  NewMessage_plural: "{{count}}  New messages",
  minute: "{{count}} minute",
  minute_plural: "{{count}} minutes",
  paypal: "PayPal",
};
