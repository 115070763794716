import React from "react";
import BasePage from "../../components/BasePage";
import "./style.scss";
import {Redirect} from "react-router-dom";
import useController from "./controller";
import InfiniteScroll from "../../components/InfiniteScroll";
import BaseButton from "../../components/BaseButton";
import ModalWindow from "../../components/ModalWindow";
import LessonPlanCreate from "./components/LessonPlanCreate";
import BaseSpinner from "../../components/BaseSpinner";
import LessonPlanItem from "./components/LessonPlanItem";
import LessonPlanUpdate from "./components/LessonPlanUpdate";
import LessonPlanDelete from "./components/LessonPlanDelete";
import SubscriptionModal from "../../modals/SubscriptionModal";


function PageLessonPlans() {
  const {
    user,
    enableScroll,
    loading,
    loadNext,
    plans,
    showCreateModal,
    onCreateStart,
    onCreateClose,
    onCreated,
    showPlanUpdateModal,
    onUpdateStart,
    onUpdateClose,
    onUpdated,
    showPlanDeleteModal,
    onDeleteStart,
    onDeleted,
    onDeleteClose,
    showSubscriptionModal,
    onSubscriptionClose
  } = useController();

  if (!user) {
    return <Redirect to="/" />;
  }
  return (
    <BasePage className="page-lesson-plans">

      <div className={"page-lesson-plans__header"}>
        <h1 className="page-lesson-plans__header-title">{"Планы уроков"}</h1>
        <BaseButton className="page-lesson-plans__header-button" name="create-plan" large={true} onClick={onCreateStart}>
          <span className="text">{"Новый план"}</span>
        </BaseButton>
      </div>
      {loading ? (
          <div className="page-lesson-plans__spinner-container">
            <BaseSpinner size={45} />
          </div>) :
        <div/>
      }
      <div>
        {plans.length > 0 ? (
          <div className="lesson-plan-table">
            <>
              <div className="lesson-plan-table__header">
                <div className="lesson-plan-table__label-name">{"Название"}</div>
                <div className="lesson-plan-table__label-time">{"Дата создания"}</div>
              </div>

              <InfiniteScroll enable={enableScroll} onNext={loadNext}>
                {plans.map((item) => (
                  <LessonPlanItem key={item.id} plan={item} onRename={onUpdateStart} onDelete={onDeleteStart}/>
                ))}
              </InfiniteScroll>
            </>
          </div>
        ) : (loading ? <div/> : (
          <div className="page-lesson-plans__no-plans">{"Здесь еще ничего нет"}
            <span>{"Чтобы начать,"}&nbsp;
              <span onClick={onCreateStart} className={"page-lesson-plans__no-plans__active-text"}>{"создайте план"}
                  </span>
                </span>
          </div>
        ))}
      </div>
      <ModalWindow className={"page-lesson-plans__modal"} show={showCreateModal} onClose={onCreateClose}>
        <LessonPlanCreate onCreated={onCreated}/>
      </ModalWindow>
      <ModalWindow className={"lesson-plan-item__rename-modal"} show={!!showPlanUpdateModal} onClose={onUpdateClose}>
        <LessonPlanUpdate planId={showPlanUpdateModal ?? ""} onUpdated={onUpdated}/>
      </ModalWindow>
      <ModalWindow className={"lesson-plan-item__delete-modal"} show={!!showPlanDeleteModal} onClose={onDeleteClose}>
        <LessonPlanDelete planId={showPlanDeleteModal ?? ""} onClose={onDeleteClose} onDeleted={onDeleted}/>
      </ModalWindow>
      <SubscriptionModal show={showSubscriptionModal} onClose={onSubscriptionClose}/>
    </BasePage>
  );
}

export default PageLessonPlans;
