import React from "react";
import CodeVerification from "./components/CodeVerification";
import EnterEmail from "./components/EnterEmail";
import SetPassword from "./components/SetPassword";

interface Props {
  email?: string;
  token?: string;
  onCancel: () => void;
  showInputEmail?: boolean;
  onResetStart: (data: { email: string; status: boolean }) => void;
  onCodeVerified: (data: { email: string; token: string }) => void;
  onSuccess?: (status: boolean) => void;
  onSuccessPromptLogin?: boolean;
}

export default function ChangePassword({
  email,
  token,
  onCancel,
  onResetStart,
  onCodeVerified,
  onSuccess,
  showInputEmail = false,
  onSuccessPromptLogin = true,
}: Props) {
  if (email && !token && !showInputEmail) {
    return <CodeVerification email={email} onCodeVerified={onCodeVerified} onCancel={onCancel} />;
  } else if (token) {
    return <SetPassword token={token} onCancel={onCancel} onSuccess={onSuccess} promptLogin={onSuccessPromptLogin} />;
  }
  return <EnterEmail email={email} onResetStart={onResetStart} onCancel={onCancel} />;
}
