import React, {useEffect, useState} from "react";
import "./style.scss";
import {ImageCard} from "../../../../GameSettings";


interface Props {
    images: ImageCard[];
    handleImageClick: (image: ImageCard) => void;
}

interface DobbleImage {
  image: ImageCard;
  width: number;
  left: number;
  top: number;
  rotate: number;
}

interface Margins {
  top: number;
  left: number;
}

interface Settings {
  minWidth: number;
  maxWidth: number;
  pad: number;
  margins: Margins[];
}

const images_4: Settings = {
  minWidth: 120,
  maxWidth: 200,
  pad: 10,
  margins: [
    { top: 60, left: 70 },
    { top: 80, left: 260 },
    { top: 260, left: 70 },
    { top: 260, left: 240 },
  ]
}

const images_6: Settings = {
  minWidth: 120,
  maxWidth: 160,
  pad: 10,
  margins: [
    { top: 50, left: 100 },
    { top: 80, left: 300 },
    { top: 180, left: 20 },
    { top: 180, left: 200 },
    { top: 300, left: 90 },
    { top: 280, left: 300 },
  ]
}

const images_8: Settings = {
  minWidth: 100,
  maxWidth: 140,
  pad: 10,
  margins: [
    { top: 50, left: 100 },
    { top: 40, left: 250 },
    { top: 180, left: 20 },
    { top: 180, left: 160 },
    { top: 300, left: 60 },
    { top: 140, left: 320 },
    { top: 320, left: 200 },
    { top: 280, left: 310 },
  ]
}

const settings: Map<number, Settings> = new Map([
    [4, images_4],
    [6, images_6],
    [8, images_8],
  ]

)

function createDobbleImage(image: ImageCard, iNumber: number, settings: Settings): DobbleImage {
  const width  = randomInt(settings.minWidth, settings.maxWidth);
  const left   = randomInt(settings.margins[iNumber].left - settings.pad, settings.margins[iNumber].left + settings.pad);
  const top    = randomInt(settings.margins[iNumber].top - settings.pad, settings.margins[iNumber].top + settings.pad);
  const rotate = randomInt(0, 360);

  return {
    image: image,
    width: width,
    left: left,
    top: top,
    rotate: rotate
  };
}
function randomInt(min: number, max: number) {
  if (max == null) { max = min; min = 0; }
  if (min > max) { const tmp = min; min = max; max = tmp; }
  return Math.floor(min + (max - min + 1) * Math.random());
}

export default function DobbleCard({ images, handleImageClick }: Props) {
  const [dobbleImages, setDobbleImages] = useState<DobbleImage[]>([]);

  useEffect(() => {
    const currentSettings = settings.get(images.length);
    setDobbleImages(images.map((value, i) => createDobbleImage(value, i, currentSettings!)));
    }
  , []);

    return (
      <div className="dobble-card">
          {dobbleImages.map(d =>
            <img key={d.image.src}
                 className="dobble-card__image"
                 style={{ width: d.width,
                   marginLeft: d.left,
                   marginTop: d.top,
                   rotate: d.rotate + "deg"
            }}
                 src={d.image.src}
                 alt={d.image.name}
                 onClick={() => handleImageClick(d.image)}
            />
          )}
      </div>
    );
}
