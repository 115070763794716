import providers from "../../lib/providers";
import useAuthUser from "../../hooks/useAuthUser";

export function useController() {
  const { user, setSubscription } = useAuthUser();

  async function onActivate(code: string) {
    const data = { userId: user?.id ?? "", code };
    const subscription = await providers.PromoCodeProvider.activate({ data });
    setSubscription(subscription);
    return subscription;
  }

  return {
    onActivate,
    setSubscription
  };
}
