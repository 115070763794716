import React from "react";
import { NotificationFactory } from "../index";

type PushSubscriber = {
  component: React.FC<{ sendNotification: NotificationFactory }>;
  key: string;
};

const context = require.context("./", true, /[a-zA-Z]\/index.ts/);

export default context.keys().reduce<PushSubscriber[]>((out, key) => {
  const module = context(key);
  out.push({
    component: module.default,
    key,
  });
  return out;
}, []);
