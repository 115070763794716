import React from "react";
import ModalWindow from "../../components/ModalWindow";
import Subscription from "../Subscription";
import {useHistory} from "react-router-dom";
import {LocationState} from "../../types";
import ModalSignUp from "../ClientSignUpController";
import useModalSignUpController from "../ClientSignUpController/modalSignUpController";

interface Props {
  show: boolean;
  onClose: () => void;
}

function SubscriptionModal({ show, onClose }: Props) {
  const history = useHistory<LocationState>();
  const { isAuth, showSignUpModal, onSignUp, onClose: onCloseSignUp } = useModalSignUpController();

  const openLoginOrSubscription = () => {
    if (!isAuth) {
      onClose();
      onSignUp();
    } else {
      history.push(`/user/subscription`);
    }
  }

  return (
    <>
      <ModalWindow className={"subscription-modal"} show={show} onClose={onClose}>
        <Subscription openLoginOrSubscription={openLoginOrSubscription}/>
      </ModalWindow>

      <ModalSignUp
        showSignUpModal={showSignUpModal}
        onClose={onCloseSignUp}
      />
    </>
  );
}

export default SubscriptionModal;
