import React, {MouseEvent, useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import "./style.scss";

const MOBILE_WARNING_ACCEPTED = "MOBILE_WARNING_ACCEPTED";

export default function MobileWarningBanner() {
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowWarning(localStorage.getItem(MOBILE_WARNING_ACCEPTED) === null)
  }, []);

  const onClickExit = (event: MouseEvent) => {
    event.stopPropagation();
    setShowWarning(false)
  }

  const onClickAccept = (event: MouseEvent) => {
    event.stopPropagation();
    localStorage.setItem(MOBILE_WARNING_ACCEPTED, "true");
    setShowWarning(false)
  }

  return (
    <>
      <CSSTransition
        in={showWarning}
        nodeRef={nodeRef}
        unmountOnExit
        timeout={{ }}
        classNames="slide-right"
      >
        <div
          className="mobile-warning"
          ref={nodeRef}
        >
          <div className="mobile-warning__wrapper">
            <span className="mobile-warning__text">
              {"На мобильном устройстве некоторые игры могут отображаться некорректно. " +
                "Пожалуйста, продолжите работу с сайтом на компьютере."}
            </span>
            <BaseButton className="mobile-warning__agree" fluid={true} onClick={onClickAccept}>
              Понятно
            </BaseButton>
          </div>
          <BaseButton
            className="mobile-warning__close"
            opacity={true}
            onClick={onClickExit}
            icon={true}
          >
            <BaseIcon size={18}>close</BaseIcon>
          </BaseButton>
        </div>
      </CSSTransition>
    </>
  );
}
