import React from "react";
import {useTranslation} from "../../../../locales";
import FullWidthPageBackground from "../../../../components/FullWidthPageBackground";
import "./style.scss";

export default function BlockAboutSite() {
  const { t } = useTranslation();

  return (
    <div className="block-about-site">
      <h1 className="block-about-site__title mb-6">{t("Контакты")}</h1>
      <div className="block-about-site__content">
        <div className="block-about-site__text">
          {/*{t(*/}
          {/*    "Тичерская основана Валентиной Лепиной, действующим преподавателем английского языка и владельцем детского центра iSpeak."*/}
          {/*)}*/}
          {<p>{t("ИП Лепина В. А.")}</p>}
          {<p>{t("ИНН 245307370080")}</p>}
          {<p>{t("ОГРНИП 321246800094004")}</p>}
          {<p>{t("г. Мончегорск, ул. Металлургов, д. 48, корп. 1")}</p>}
          <br/>
          {<p><a href="mailto:support@teacherskaya.ru">{"support@teacherskaya.ru"}</a></p>}
        </div>
      </div>
      <FullWidthPageBackground className="block-about-site__background" />
    </div>
  );
}
