import React from "react";
import GameWithImageSettings, {ImageCard} from "../../../../GameSettings";
import {LessonGameSettings} from "../../../../../types";


interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

export default function VocabularyIntroductionGameSettings({ onGameConfigured }: Props) {
    const handleImageSelection = (images: ImageCard[]) => {
      const game: LessonGameSettings = {
        type: "VOCABULARY_INTRODUCTION",
        imageCount: images.length,
        images: images,
      }
      onGameConfigured(game);
    }
    return (
      <GameWithImageSettings handleImageSelection={handleImageSelection}/>
    );
}
