import {Game} from "./types";
import config from "./config";

export enum HISTORY_ACTIONS {
  POP = "POP",
  PUSH = "PUSH",
  REPLACE = "REPLACE",
}

export const GAME_TYPES: Game[] = [
  {
    type: "DOBBLE",
    name: "Доббль",
    description:
      "В данной игре на любой отдельно взятой паре кружков обязательно присутствует один совпадающий рисунок. " +
      "Суть игры заключается в том, чтобы как можно быстрее обнаружить его и назвать вслух."
    ,
    briefDescription: "Игра на скорость и внимательность. Нажмите на картинку, совпадающую на обоих карточках",
    path: "/games/dobble",
    imgSrc: config.assetsUrl + "/dobble-game.jpg",
  },
  {
    type: "LOTTO",
    name: "Лото",
    description:
      "Игра состоит в том, что участники закрывают на карточках изображения, выпавшие случайным образом. " +
      "Побеждает тот, кто первым закроет все изображения на своей карточке. Играть могут от 1 до 5-х " +
      "человек (при большем количестве участников можно разделиться на команды)."
    ,
    briefDescription: "Листайте картинки, чтобы узнать, у кого из игроков быстрее закроется вся карточка",
    path: "/games/lotto",
    imgSrc: config.assetsUrl + "/lotto-game.jpg",
  },
  {
    type: "HIDDEN_NUMBER",
    name: "Загаданное число",
    description:
      "Игра состоит в том, что студент загадывает число. Другие участники выбирают число, а студент говорит, " +
      "больше или меньше выбранное число загаданного. Необходимо как можно быстрее отгадать число."
    ,
    briefDescription: "Игра на изучение чисел и логику. Загадайте число и предложите другим его угадать",
    path: "/games/hidden-number",
    imgSrc: config.assetsUrl + "/vocabulary-introduction-game.jpg",
  },
  {
    type: "TIC_TAC_TOE",
    name: "Крестики-нолики",
    description:
      "В данной игре необходимо разделить студентов на две команды. Игрок от одной из команд выбирает изображение, " +
      "на которое собирается поставить крестик (нолик) и строит предложение с этим словом. Учитель передвигает крестик " +
      "(нолик) на позицию, выбранную учеником. Выигрывает команда, собравшая по горизонтали, вертикали или диагонали 3 " +
      "карточки (в поле 3x3) или 4 карточки (в поле 5x5)."
    ,
    briefDescription: "Перетаскиваете крестики или нолики на поле с картинками, чтобы выстроить символы в один ряд",
    path: "/games/tic-tac-toe",
    imgSrc: config.assetsUrl + "/tic-tac-toe-game.jpg",
  },
  {
    type: "MEMORY",
    name: "Мемори",
    description:
      "Игра состоит из парных картинок, изначально лежащие рубашкой вверх. " +
      "Основной целью является найти как можно больше парных карточек."
    ,
    briefDescription: "Игра на запоминание. Переворачивайте картинки и находите пары",
    path: "/games/memory",
    imgSrc: config.assetsUrl + "/memory-game.jpg",
  },
  {
    type: "SHADOW",
    name: "Тени",
    description:
      "Задача игры «тени» – угадать изображение по его силуэту."
    ,
    briefDescription: "Угадывайте изображение по силуэту и нажимайте на тень для того, чтобы увидеть ответ",
    path: "/games/shadow",
    imgSrc: config.assetsUrl + "/shadow-game.jpg",
  },
  {
    type: "VOCABULARY_INTRODUCTION",
    name: "Знакомство с лексикой",
    description:
      "Данный раздел предназначен для знакомства с новыми словами. Просматривайте картинки и читайте подписи к ним вместе со студентами."
    ,
    briefDescription: "Листайте картинки со словами для ознакомления с лексикой",
    path: "/games/vocabulary-introduction",
    imgSrc: config.assetsUrl + "/vocabulary-introduction-game.jpg",
  },
];
