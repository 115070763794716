import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {EVENT_NAMES} from "@teacherskaya/api-provider/dist";
import emitter from "./lib/emitter";
import {getLoginData} from "./lib/authHelper";
import useAuthUser, {AuthUserContextProvider} from "./hooks/useAuthUser";
import {AppStateContextProvider} from "./hooks/useAppState";
import PageHeader from "./components/PageHeader";
import RouteController from "./router/RouteController";
import MainRouterView from "./router/MainRouterView";
import PushManager from "./components/PushManager";
import "./styles/App.scss";
import CookieBanner from "./components/CookieBanner";

function App() {
  const { setLoginData } = useAuthUser();

  useEffect(() => {
    const onEvent = (data: any) => {
      const oldData = getLoginData();
      if (JSON.stringify(data) === JSON.stringify(oldData)) return;
      setLoginData(data);
    };
    emitter.on(EVENT_NAMES.SET_USER, onEvent);
    return () => emitter.off(EVENT_NAMES.SET_USER, onEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader />
      <main className="main">
        <div className="container">
          <MainRouterView />
        </div>
      </main>
      <RouteController />
      <PushManager />
      <CookieBanner/>
    </>
  );
}

export function WrappedApp() {
  return (
    <AuthUserContextProvider>
      <AppStateContextProvider>
        <App />
      </AppStateContextProvider>
    </AuthUserContextProvider>
  );
}

export default function BrowserApp() {
  return (
    <Router>
      <WrappedApp />
    </Router>
  );
}
