import React from "react";
import BaseForm from "../../components/BaseForm";
import BaseButton from "../../components/BaseButton";
import { useTranslation } from "../../locales";
import useViewController from "./viewController";
import "./style.scss";

function ProfileSettingsPage() {
  const {
    fields,
    values,
    onChange,
    onSave,
    errors,
    setErrors,
    loadingForm,
  } = useViewController();
  const { t } = useTranslation();

  return (
    <div className="profile-settings">
      <BaseForm
        className="profile-settings__form"
        fields={fields}
        values={values}
        onChange={onChange}
        onSubmitEnter={onSave}
        errors={errors}
        setErrors={setErrors}
      >
        <BaseButton
          loading={loadingForm}
          className="profile-settings__button"
          onClick={onSave}
          large={true}
          name="submit"
        >
          {t("Сохранить")}
        </BaseButton>
      </BaseForm>
    </div>
  );
}

export default ProfileSettingsPage;
