import React from "react";
import "./style.scss";
import {HiddenNumberItem} from "../HiddenNumberGamePlay";

interface Props {
  numbers: HiddenNumberItem[];
  handleClick: (item: HiddenNumberItem) => void;
}

export default function HiddenNumberCard({ numbers, handleClick }: Props) {
  return (
    <div className="hidden-number-card" style={{gridTemplateColumns: "1fr ".repeat(numbers.length / 5)}}>
      {numbers.map(
        i => (
          <div key={i.id} className="hidden-number-card__number" onClick={() => handleClick(i)}>
            <div key={i.id} className={i.selected ? "hidden-number-card__number__flipped" : ""}>
              <div key={i.id} className={"hidden-number-card__number__text" + (i.disabled ? " __disabled" : "")}>
                {i.id}
              </div>
            </div>
          </div>
        )
      )}
    </div>);
}
