import React from "react";
import { useTranslation } from "../../locales";
import useAuthUser from "../../hooks/useAuthUser";
import BaseCard from "../../components/BaseCard";
import BaseButton from "../../components/BaseButton";
import ModalWindow from "../../components/ModalWindow";
import ChangePassword from "../../components/ChangePassword";
import useController from "./controller";
import "./styles.scss";

export default function PageSettingsChangePassword() {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { start, token, showModal, onStart, onCodeVerified, onClose, onResetStart, onSuccess } = useController();

  return (
    <>
      <BaseCard>
        <div className="page-change-password">
          <h2 className="headline-2 mb-5">{t("Изменить пароль")}</h2>

          <div className="body">
            {t("Если вы хотите изменить пароль, нажмите на кнопку ниже.")}
            <br />
            {t(" Мы отправим на ваш emal адрес инструкцию.")}
          </div>
          {(
            <div>
              <BaseButton className="mt-5 ml-auto" large={true} onClick={onStart}>
                {t("Изменить пароль")}
              </BaseButton>
            </div>
          )}
        </div>
      </BaseCard>
      <ModalWindow className={"change-password-modal"} show={showModal} onClose={onClose}>
        <ChangePassword
          email={user?.email ?? ""}
          token={token}
          showInputEmail={start}
          onCancel={onClose}
          onResetStart={onResetStart}
          onCodeVerified={onCodeVerified}
          onSuccess={onSuccess}
          onSuccessPromptLogin={false}
        />
      </ModalWindow>
    </>
  );
}
