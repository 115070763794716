import providers from "../../../../lib/providers";
import {useState} from "react";
import {LessonGameSettings, LocationState} from "../../../../types";
import {useHistory} from "react-router-dom";

export function useController(planId: string) {
  const history = useHistory<LocationState>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [game, setGame] = useState<LessonGameSettings>();

  async function onCreate(name: string) {
    const data = {
      planId: planId,
      name: name,
      type: game!.type,
      cardCount: game!.cardCount,
      imageOnCardCount: game!.imageOnCardCount,
      imageCount: game!.imageCount,
      imageIds: game!.images.map(i => i.id.toString())
    };
    await providers.LessonGameProvider.create({data});
  }

  const onCreateStart = (gamet: LessonGameSettings) => {
    setGame(gamet);
    setShowCreateModal(true);
  };

  const onCreateClose = () => {
    setShowCreateModal(false);
  };

  const onCreated = () => {
    setShowCreateModal(false);
    history.push('/plans/' + planId);
  };

  return {
    onCreate,

    onCreateStart,
    onCreateClose,
    showCreateModal,
    onCreated
  };
}
