import useAuthUser from "../../hooks/useAuthUser";
import {useState} from "react";

export default function useController() {
  const { user, hasSubscription } = useAuthUser();
  const [showPromoCodeActivationModal, setShowPromoCodeActivationModal] = useState(false);

  const onPromoCodeActivationStart = () => setShowPromoCodeActivationModal(true);
  const onPromoCodeActivationClose = () => setShowPromoCodeActivationModal(false);

  return {
    user,
    hasSubscription,
    showPromoCodeActivationModal,
    onPromoCodeActivationStart,
    onPromoCodeActivationClose,
  };
};