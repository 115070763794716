import providers from "../../../../lib/providers";
import { InputPasswordResetChange } from "@teacherskaya/api-provider/dist/api/PasswordResetProvider";

export function useController() {
  function onSetPassword(data: InputPasswordResetChange) {
    providers.PasswordResetProvider.setReceiveErrors(false);
    return providers.PasswordResetProvider.change({ data });
  }

  return { onSetPassword };
}
