import React from "react";
import BaseButton from "../../components/BaseButton";
import {useTranslation} from "../../locales";
import BaseIcon from "../../components/BaseIcon";
import ContentHeader from "../../components/ContentHeader";
import "./style.scss";

interface Props {
  openLoginOrSubscription?: () => void;
}

function Subscription({ openLoginOrSubscription }: Props) {
  const { t } = useTranslation();

  const messageText = "Пользуйтесь платформой без ограничений!";
  const listText = [
    "Расширенные настройки игр",
    "Все категории картинок",
    "Бесконечное количество планов"
  ]

  return (
    <>
        <div className={"subscription-modal__container"}>
          <ContentHeader className="mb-4">
            <div>{"Доступно только по подписке"}</div>
          </ContentHeader>
          <p className="body text-center mt-4">{messageText}</p>
          <div className={"subscription-modal__list-container"}>
            {listText.map((text, i) =>
              <div key={i} className={"subscription-modal__list-item"}>
                <BaseIcon className="subscription-modal__list-icon">{"favorites"}</BaseIcon>
                {text}
              </div>
            )}
          </div>
          <BaseButton className="subscription-modal__button" large={true} onClick={openLoginOrSubscription}>
            {t("Купить за 199 р. / месяц >")}
          </BaseButton>

          <div className={"subscription-modal__offer"}>
            Получите один бесплатный месяц с помощью промокода <a target="_blank" href="https://vk.com/teacherskaya_platform">из нашей группы</a> в блоке "Получить&nbsp;промокод"!
          </div>
        </div>
    </>
  );
}

export default Subscription;
