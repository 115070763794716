import React from "react";
import ShortCodeVerification from "../../components/ShortCodeVerification";
import { useTranslation } from "../../locales";
import ConfirmationLongCode from "./components/ConfirmationLongCode";
import useViewController from "./viewController";

export default function EmailVerificationByCode({ onClose, code }: { onClose: () => void; code?: string | null }) {
  const { t } = useTranslation();
  const { email, onResendCode, onSendCode, onCancel, status } = useViewController(onClose, code);

  if (code) {
    return <ConfirmationLongCode status={status} />;
  }

  return (
    <ShortCodeVerification
      title={t("Подтверждение почты")}
      email={email}
      onCancel={onCancel}
      resendCode={onResendCode}
      sendCode={onSendCode}
    />
  );
}
