import React from "react";
import { useTranslation } from "../../locales";
import BasePage from "../../components/BasePage";
import FaqItem from "./compomemts/FAQItem";
import useController from "./controller";
import "./style.scss";

export default function PageFAQ() {
  const { t } = useTranslation();
  const { faqList } = useController();

  return (
    <BasePage className="page-faq">
      <h1 className="page-faq__title">{t("Часто задаваемые вопросы")}</h1>
      <div className="page-faq__content">
        {faqList.map((el, i) => (
          <FaqItem className={"mb-3"} key={i} item={el} />
        ))}
      </div>
    </BasePage>
  );
}
