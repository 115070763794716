import React from "react";
import BasePage from "../../components/BasePage";
import BlockAboutSite from "./components/BlockAboutSite";
import BlockAboutOwner from "./components/BlockAboutOwner";
import "./style.scss";

export default function PageAboutUs() {
  return (
    <BasePage className="page-about-us">
      <BlockAboutSite />
      {/*<BlockAboutOwner />*/}
    </BasePage>
  );
}
