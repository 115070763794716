import React, {useState} from "react";
import "./style.scss";
import {LessonGameSettings} from "../../types";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcon";
import ContentHeader from "../ContentHeader";
import BasePage from "../BasePage";
import {GamePlay} from "../Games";
import MobileWarningBanner from "../MobileWarningBanner";

export interface Props {
  game: LessonGameSettings;
  title?: string;
  onClickBackButton?: () => void;
  onClickSettingsButton?: () => void;
  addToPlanButton?: () => void;
}

function PageGamePlay({ game, title, onClickBackButton, onClickSettingsButton, addToPlanButton }: Props) {
  const [ key, setKey ] = useState<number>(0);

  const onClickNewGame = () => {
    setKey(prev => prev + 1);
  }

  const prepend = (
    <>
      <div className="page-game-play__header">
        <div className={"page-game-play__header__title-wrapper"}>
          {
            <BaseButton
              className="page-game-play__header__title-wrapper__back-button mr-2"
              round
              borderless
              onClick={onClickBackButton}
            >
              <BaseIcon className="mx-n1">back</BaseIcon>
            </BaseButton>
          }
          <ContentHeader className="page-game-play__header__title-wrapper-title text-left">{title ?? game.name ?? ""}</ContentHeader>
        </div>
        <div className="page-game-play__header-buttons">
          <BaseButton className="page-game-play__new-game" name="new-game" outline={true} onClick={onClickNewGame}>
            <BaseIcon className="mx-n1">repeat</BaseIcon>
          </BaseButton>
          {/*todo пофиксить выход для из плана*/}
          {onClickSettingsButton ?
            <BaseButton className="page-game-play__settings-button" name="back-to-settings" outline={true} onClick={onClickSettingsButton}>
              <span className="text">{"К настройкам"}</span>
            </BaseButton>
            : <div/>}
          {addToPlanButton ?
            <BaseButton className="page-game-play__add-to-plan-button" name="add-to-plan" onClick={addToPlanButton}>
              <span className="text">{"Добавить в план"}</span>
            </BaseButton>
            : <div/>}
        </div>
      </div>
    </>
  );
  return (
    <BasePage className="page-game-play" prepend={prepend}>
      <div className={"page-game-play__container"}>
        <GamePlay key={key} game={game} />
      </div>
      <MobileWarningBanner/>
    </BasePage>
  );
}

export default PageGamePlay;
