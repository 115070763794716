import { format, parseISO } from "@teacherskaya/tools/dist/lib/TimeHelpers";

export function get12formatTime(time: string) {
  return format(parseISO(time), "h:mm aaa");
}

export function get24formatTime(time: string) {
  return format(parseISO(time), "H:mm");
}

export function getDate(time: string) {
  const [dayOfWeek, dayOfMonth, month, year, hours, minutes] = format(parseISO(time), "eee,dd,MM,yyyy,HH,mm").split(",");

  return {
    dayOfWeek,
    dayOfMonth,
    month,
    year,
    hours,
    minutes
  };
}
