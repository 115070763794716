import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../../../locales";
import BasePhoto from "../../../../components/BasePhoto";
import "./index.scss";
import { LocationDescriptor } from "history";
import {Game} from "../../../../types";

interface Props {
  className?: string;
  classNameGameImage?: string;
  game: Game;
  to: LocationDescriptor;
}

function GameImage({ className = "", game, to, classNameGameImage = "" }: Props) {
  const { t } = useTranslation();

  return (
    <div className={"card-game-image " + className}>
      <Link to={to}>
        <BasePhoto className={classNameGameImage} src={game.imgSrc} alt="Превью" title={t("Нет картинки")} />
      </Link>
    </div>
  );
}

export default GameImage;
