import {NavigationMenuItemInterface} from "../../types";
import {useTranslation} from "../../locales";
import useController from "./controller";

function useViewController() {
  const { t } = useTranslation();
  const { user } = useController();
  const itemsList: NavigationMenuItemInterface[] = [
    {
      title: t("Подписка"),
      name: "subscription",
      icon: "crown-line",
      to: "/user/subscription",
    },
    {
      title: t("Основная информация"),
      name: "settings",
      icon: "signup",
      to: "/user/settings",
    },
    {
      title: t("Изменить пароль"),
      name: "password",
      icon: "show-off",
      to: "/user/change-password",
    },
    {
      title: t("Выйти"),
      name: "logout",
      icon: "logout",
      to: "/logout",
    },
  ];

  return {
    user,
    itemsList,
  };
}

export default useViewController;
