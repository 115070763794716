import './style.scss';
import React from "react";
import {ImageCard} from "../../../../GameSettings";

interface Props {
  image: ImageCard;
  frame: any;
}

export default function VocabularyIntroductionImage({ frame, image }: Props) {
  return (
    <div className="vocabulary-introduction-image">
      <div>
      <div className="vocabulary-introduction-image__images">
        <img className="vocabulary-introduction-image__frame" src={frame}/>
        <img className="vocabulary-introduction-image__image" src={image.src}/>
      </div>
      <div className="vocabulary-introduction-image__word">
        <div className="vocabulary-introduction-image__word-text">{image.name}</div>
      </div>
      </div>
    </div>
  )
}