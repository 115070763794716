import React, {useEffect, useState} from "react";
import "./style.scss";
import MemoryCard, {MemoryCardItem} from "../../components/MemoryCard";
import useCacheImagesController from "../../../../../lib/cacheImages";
import BaseSpinner from "../../../../../components/BaseSpinner";
import {ImageCard} from "../../../../GameSettings";


interface Props {
    images: ImageCard[];
}

function getColumnNumber(count: number): number {
    const minColumns = 4;
    for (let i = 5; i > 2; i--) {
        if(count % i === 0 && count / i >= minColumns && count / i >= i) {
            return count / i;
        }
    }
    return count / 2;
}

export default function MemoryGamePlay({ images }: Props) {
    const [cards, setCards] = useState<MemoryCardItem[]>([]);
    const [turn, setTurns] = useState(0);
    const [choiceOne, setChoiceOne] = useState<MemoryCardItem | null>(null);
    const [choiceTwo, setChoiceTwo] = useState<MemoryCardItem | null>(null);
    const [disabled, setDisabled] = useState(false);
    const {loading, cacheImages} = useCacheImagesController();

    const shuffleCards = () => {
        setChoiceOne(null);
        setChoiceTwo(null);
        setTurns(0);

        const shuffledCards = [...images, ...images]
            .sort(() => Math.random() - 0.5)
            .map((card, index) => ({...card, id: index + 1, matched: false}));

        setCards(shuffledCards);
    }

    const handleChoice = (card: MemoryCardItem) => {
        choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
    }

    useEffect(() => {
        cacheImages(images, true);
        shuffleCards();
    }, []);

    useEffect(() => {
        if (choiceOne && choiceTwo) {
            setDisabled(true);
            if (choiceOne.src === choiceTwo.src) {
                setCards(prevCards => prevCards.map(card => card.src === choiceOne.src ? {
                    ...card,
                    matched: true
                } : card))
                resetTurn()
            } else {
                setTimeout(() => resetTurn(), 2500)
            }
        }
    }, [choiceOne, choiceTwo]);

    const resetTurn = () => {
        setTurns(prevTurns => prevTurns + 1)
        setChoiceOne(null)
        setChoiceTwo(null)
        setDisabled(false)
    }

    return (
        <div>
            {loading ? (
                <div className="memory-game__spinner-container">
                    <BaseSpinner size={45} />
                </div>) :
              <div>
                  <div className="memory-game__card-grid" style={{ gridTemplateColumns: "1fr ".repeat(getColumnNumber(cards.length)) }}>
                      {cards.map(card => (
                        <MemoryCard
                          key={card.id}
                          card={card}
                          flipped={card === choiceOne || card === choiceTwo || card.matched}
                          disabled={disabled}
                          handleChoice={handleChoice}
                        />
                      ))}
                  </div>
                  <div className="memory-game__turns">
                      <span>{"Попыток: " + turn}</span>
                  </div>
                  {/*<div className="memory-game__buttons">*/}
                  {/*    <BaseButton className="memory-game__buttons__new-game" onClick={shuffleCards}>*/}
                  {/*        {"Начать заново"}*/}
                  {/*    </BaseButton>*/}
                  {/*</div>*/}
              </div>}
        </div>
    );
}
