import {useController} from "./controller";
import {useTranslation} from "../../locales";
import useBaseForm from "../../hooks/useBaseForm";
import {FormValues} from "../../types";
import {validateFieldsWithException} from "../../lib/validation";
import {parseErrors} from "../../lib/requestHelpers";

export function useViewController() {
  const { t } = useTranslation();

  const { fields, values, errors, setErrors, setValues } = useBaseForm([
    {
      name: "name",
      type: "text",
      placeholder: t("Промокод"),
      validation: {
        required: true,
      },
      autocomplete: "name",
      textAlign: "center",
    },
  ]);
  const { onActivate, setSubscription } = useController();
  const onChange = (values: FormValues) => setValues(values);

  const sendActivate = async (cb?: () => void) => {
    try {
      validateFieldsWithException(fields, values);
      await onActivate(values.name);
      cb && cb();
    } catch (e) {
      if (e.length > 0 && e[0].code === "PAGE_NOTFOUND") {
        e[0].details = { message: "Неверный промокод" };
      }
      setErrors(parseErrors(e));
    }
  };

  return {
    fields,
    values,
    errors,
    setValues,
    setErrors,
    onChange,
    sendActivate,
    setSubscription
  };
}
