import { ErrorDTO } from "@teacherskaya/api-provider/dist/lib/apiErrorHandler";

export function parseErrors(errors: ErrorDTO[]) {
  return errors.reduce<Record<string, string>>((out, errorDTO) => {
    out[errorDTO.details?.propertyPath ?? "common"] = getErrorMessage(errorDTO);
    return out;
  }, {});
}

export function getErrorMessage(error: ErrorDTO) {
  return error.details?.message || error.error?.message || error.message;
}
