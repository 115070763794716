import useAuthUser from "../../hooks/useAuthUser";
import {LessonPlan} from "@teacherskaya/api-provider/dist/api/LessonPlanProvider";
import {useEffect, useState} from "react";
import providers from "../../lib/providers";
import {useHistory, useRouteMatch} from "react-router-dom";
import {LocationState} from "../../types";
import {LessonGame} from "@teacherskaya/api-provider/dist/api/LessonGameProvider";

const useController = (planId: string) => {
  const { user } = useAuthUser();
  const match = useRouteMatch();
  const history = useHistory<LocationState>();

  const [plan, setPlan] = useState<LessonPlan | null>(null);
  const [games, setGames] = useState<LessonGame[] | undefined>();
  const [loading, setLoading] = useState(false);

  const getPlan = async () => {
    const response = await providers.LessonPlanProvider.findById({ id: planId });
    setPlan(response);
    setGames(response.games);
  };

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    getPlan().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const onCreateStart = () => {
    history.push(`${match.url}/new-game`);
  };

  const onCreateClose = () => {
    setShowCreateModal(false);
  };

  const onCreated = () => {
    setShowCreateModal(false);
    setLoading(true);
    // getData().finally(() => setLoading(false));
  };

  const [showGameUpdateModal, setShowGameUpdateModal] = useState<string | undefined>(undefined);

  const onUpdateStart = (gameId: string) => {
    setShowGameUpdateModal(gameId);
  };

  const onUpdateClose = () => {
    setShowGameUpdateModal(undefined);
  };

  const onUpdated = (newName: string) => {
    setGames((games) => {
      return games?.map(i => i.id !== showGameUpdateModal ? i : { ...i, name: newName });
    });
    setShowGameUpdateModal(undefined);
  };

  const [showGameDeleteModal, setShowGameDeleteModal] = useState<string | undefined>(undefined);

  const onDeleteStart = (gameId: string) => {
    setShowGameDeleteModal(gameId);
  };

  const onDeleteClose = () => {
    setShowGameDeleteModal(undefined);
  };

  const onDeleted = () => {
    setGames((games) => {
      return games?.filter(i => i.id !== showGameDeleteModal);
    });
    setShowGameDeleteModal(undefined);
  };

  return {
    // enableScroll: state?.hasNextPage ?? !loading,
    // plans: state?.result ?? [],
    loading,
    // loadNext,
    user,
    plan,
    games,

    showCreateModal,
    onCreateStart,
    onCreated,
    onCreateClose,

    showGameUpdateModal,
    onUpdateStart,
    onUpdated,
    onUpdateClose,

    showGameDeleteModal,
    onDeleteStart,
    onDeleted,
    onDeleteClose,
  };
};

export default useController;
