import React from "react";
import "./style.scss";
import BasePhoto from "../../../../components/BasePhoto";
import {Link} from "react-router-dom";

interface Props {
  className?: string;
  children: React.ReactNode;
  title: string;
  imagePath: string;
  gamePath: string;
}
export default function GameItem({ className, children, title, imagePath, gamePath }: Props) {
  const cls = ["game-item"];

  if (className) cls.push(className);

  return (
    <Link to={gamePath} className={cls.join(" ")}>
      <div className={"game-item__game-image-container"}>
        <BasePhoto className={"game-item__game-image"} src={imagePath} alt="Превью" title={"Нет картинки"} />
      </div>
      <div className={"game-item__game-text-container"}>
        <h3 className="game-item__title">{title}</h3>
        <div className="game-item__content">{children}</div>
      </div>
    </Link>
  );
}
