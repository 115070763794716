import React, { CSSProperties, useRef } from "react";
import { BaseCheckboxField } from "../../types";
import "./style.scss";

interface Props extends Omit<BaseCheckboxField, "type" | "label"> {
  value: boolean | string;
  onChange: (value: boolean) => void;
  children?: React.ReactChild;
  style?: CSSProperties;
}

function CheckboxInput({ autofocus, name, value, onChange, children, className, style, labelClassName }: Props) {
  const ref = useRef<HTMLInputElement | null>(null);
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    onChange(value);
  };

  const onLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    ref.current && ref.current.click();
  };

  const cls = ["checkbox-input"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")} style={style}>
      <label className={"checkbox-input__container " + (labelClassName ? labelClassName : "")} onClick={onLabelClick}>
        <input
          ref={ref}
          className="checkbox-input__input __input"
          type="checkbox"
          checked={typeof value === "boolean" ? value : false}
          onChange={onChangeHandler}
          name={name}
          autoFocus={autofocus}
        />
        <span className="checkbox-input__container __checkmark" />
        {children}
      </label>
    </div>
  );
}

export default CheckboxInput;
