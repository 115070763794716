import { InputJoinData } from "@teacherskaya/api-provider/dist/api/LoginProvider";
import providers from "../../lib/providers";
import { parseErrors } from "../../lib/requestHelpers";
import { SetErrors } from "../../types";

export function useController(setErrors: SetErrors) {
  async function onJoin(data: InputJoinData, cb?: () => void) {
    try {
      await providers.LoginProvider.join({ data: data });
      if (cb) cb();
    } catch (e) {
      setErrors(parseErrors(e));
    }
  }

  return {
    onJoin,
  };
}
