import {useState} from "react";
import {ImageCard} from "../components/GameSettings";

const useCacheImagesController = () => {
  const [loading, setLoading] = useState(false);

  const cacheImages = async (images: ImageCard[], loading: boolean = false) => {
    const imgPromise = (i: ImageCard) => new Promise((resolve, reject) => {
      if (loading) setLoading(true);

      const img = new Image();

      img.src = i.src;
      img.onload = resolve;
      img.onerror = reject;
    });

    const timeoutPromise = new Promise((resolve, _) => {
      setTimeout(() => resolve(), 15000);
    })

    if (loading) {
      await Promise.race([Promise.all(images.map(i => imgPromise(i))), timeoutPromise]).then(() => {}, () => {});
    } else {
      await Promise.all(images.map(i => imgPromise(i)));
    }

    setLoading(false)
  };

  const cacheAssets = async (images: string[]) => {
    const promises = images.map(i => new Promise((resolve, reject) => {
      const img = new Image();

      img.src = i;
      img.onload = resolve;
      img.onerror = reject;
    }));

    await Promise.all(promises);
  };

  return {
    loading,
    setLoading,
    cacheImages,
    cacheAssets,
  };
};

export default useCacheImagesController;
