import './style.scss';
import React, {useEffect, useState} from "react";
import GameImageCategory from "../GameImageCategory";
import BaseButton from "../../../BaseButton";
import BaseIcon from "../../../BaseIcon";
import {ImageCard} from "../../index";
import useController from "./controller";
import {GameImage} from "@teacherskaya/api-provider/dist/api/GameImageProvider";
import config from "../../../../config";
import useSubscriptionModalController from "../../../../modals/SubscriptionModal/subscriptionModalController";
import SubscriptionModal from "../../../../modals/SubscriptionModal";

interface Props {
  minSelected?: number;
  maxSelected?: number | null;
  needSelected?: number;
  handleImageSelection: (images: ImageCard[]) => void;
}

function groupByCategory(images: ImageCard[]): Map<string, ImageCard[]> {
  return images.reduce((images: Map<string, ImageCard[]>, currentImage: ImageCard) => {
    const category = currentImage.category;
    if (!images.has(category)) images.set(category, []);
    // @ts-ignore
    images.get(category).push(currentImage);
    return images;
  }, new Map<string, ImageCard[]>());
}

export default function GameImageSelection({ needSelected, handleImageSelection }: Props) {
  const { hasSubscription, showSubscriptionModal, onSubscription, onSubscriptionClose } = useSubscriptionModalController();
  const { backImages, loading } = useController();
  const cls = "game-image-selection";

  useEffect(() => {
    if(!backImages) return;
    setImages(backImages!.map((i: GameImage) => ({
        id: Number.parseInt(i.id),
        category: i.category,
        name: i.word,
        urlPart: i.urlPart,
        src: config.s3Url + "/" + i.urlPart,
        thumbnailSrc: config.thumbnailUrl + "/" + i.urlPart,
        bySubscription: hasSubscription ? false : i.category !== 'Еда',
        selected: false,
      })));
  }, [backImages])

  const [images, setImages] = useState<ImageCard[]>([]);
  const [selectedCount, setSelectedCount] = useState<number>(0);

  const openLoginOrSelectNumber = (image: ImageCard, selected?: boolean) => {
    if(image.bySubscription && !hasSubscription) {
        onSubscription();
    } else {
      setImages(prevImages => prevImages.map(i => i.src === image.src ? {
        ...i,
        selected: selected === undefined ? !i.selected : selected,
      } : i));
    }
  }

  const selectAll = () => {
    const selected = hasSubscription
      ? images.length === selectedCount
      : images.filter(i => !i.bySubscription).length === selectedCount;
    images.filter(i => hasSubscription ? true : !i.bySubscription).forEach(i => openLoginOrSelectNumber(i, !selected));
  }

  useEffect(() => {
    const selected = images.filter(i => i.selected);
    setSelectedCount(selected.length);
  }, [images]);

  const selectImages = () => {
    const selected = images.filter(i => i.selected).sort(() => Math.random() - 0.5);
    handleImageSelection(needSelected ? selected.splice(0, needSelected) : selected);
  }

  return (
    <div>
      {loading ? <div/> : (
        <>
          <div className={cls + "__title"}>Выберите картинки для игры</div>
          <div className={cls + "__wrapper"}>
            <div className={cls + "__header"}>
              <div className={cls + "__header__select-all"} onClick={selectAll}>
                <div className={cls + "__header__select-all__text"}>{"Выбрать все"}</div>
                <BaseIcon className={cls + "__header__select-all__button "
                  + (selectedCount === images.length ? "__selected" : "")}
                >{(selectedCount === images.length) ? "done" : "done-primary"}</BaseIcon>
              </div>
            </div>
            <div className={cls + "__categories"}>
              {
                Array.from(groupByCategory(images)).map(([key , value], i) =>
                  (<GameImageCategory key={key} categoryName={key}
                                      images={value}
                                      bySubscription={value[0]?.bySubscription}
                                      activeCategory={hasSubscription ? false : i == 0}
                                      handleSelect={openLoginOrSelectNumber}/>))
              }
            </div>
            <div className={cls + "__bottom-wrapper"}>
              <div className={cls + "__bottom"}>
                {(!needSelected || selectedCount <= needSelected) ?
                  <div className={cls + "__bottom-selected-count"}>
                    {"Выбрано: " + selectedCount + (needSelected ? " / " + needSelected : "")}
                  </div> :
                  <div className={cls + "__bottom-random-selected-count"}>
                    {"Случайным образом будет выбрано"}<br/>{needSelected + " картинок из отмеченных"}
                  </div>}
                <BaseButton className={cls + "__bottom-continue"} name="continue"
                            disabled={selectedCount === 0 || (!!needSelected && selectedCount < needSelected)} large={true}
                            onClick={selectImages}>
                  <span className="text">{"Продолжить"}</span>
                </BaseButton>
              </div>
            </div>

            <div/>
          </div>
          <SubscriptionModal show={showSubscriptionModal} onClose={onSubscriptionClose}/>
        </>
      )}
    </div>
  )
}