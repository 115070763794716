import {useState} from "react";
import {useTranslation} from "../../locales";
import {BaseMenuItemInterface} from "../../types";
import useBreakPoints from "../../hooks/useBreakPoints";
import {LOGO_URL} from "../../lib/urlHelper";
import useController from "./controller";

function useViewController() {
  const {
    user,
    hasSubscription,
    showPromoCodeActivationModal,
    onPromoCodeActivationStart,
    onPromoCodeActivationClose,
  } = useController();
  const { t } = useTranslation();
  const [logoSrc, setLogoSrc] = useState(LOGO_URL);

  const setDefaultLogo = () => {
    setLogoSrc(process.env.PUBLIC_URL + "/images/logo.svg");
  };

  const logoSrcMobile = process.env.PUBLIC_URL + "/images/logo-mobile.svg"

  const { mobileSMOnly: isMobileSM } = useBreakPoints();

  const itemsList: BaseMenuItemInterface[] = [
    {
      name: "subscription",
      icon: "crown-line",
      title: t("Подписка"),
      to: "/user/subscription",
      divider: true,
    },
    {
      name: "settings",
      icon: "settings",
      title: t("Настройки"),
      to: "/user/settings",
      divider: true,
    },
    {
      name: "logout",
      icon: "logout",
      title: t("Выйти"),
      to: "/logout",
    },
  ];

  return {
    logoSrc,
    setDefaultLogo,
    logoSrcMobile,
    itemsList,
    isMobileSM,
    user,
    hasSubscription,
    showPromoCodeActivationModal,
    onPromoCodeActivationStart,
    onPromoCodeActivationClose,
  };
}

export default useViewController;
