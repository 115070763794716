import {useTranslation} from "../../locales";

export default function useController() {
    const { t } = useTranslation();

    const faqList = [
      {
        question: t("Могу ли я бесплатно протестировать платформу, прежде чем куплю тарифный план?"),
        answer: t(
          "Зарегистрируйтесь на сайте и на бесплатном тарифе вы сможете создавать игры с ограниченным выбором тем."
        ),
      },
      {
        question: t("Как оплатить тариф?"),
        answer: t(
          "Онлайн-оплата доступна из аккаунта для зарегистрированных пользователей."
        ),
      },
      {
        question: t("За какой период списывается оплата?"),
        answer: t(
          "Тарифный план оформляется на 30 дней с даты оплаты."
        ),
      },
        {
            question: t("У меня есть вопрос (предложение, замечание), куда можно обратиться?"),
            answer: "Напишите нам на почту support@teacherskaya.ru или в группу вконтакте"
        },
    ];

  return {
    faqList,
  };
}
