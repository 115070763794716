import React from "react";
import LandingBackground from "../LandingBackground";
import BaseButton from "../../../../components/BaseButton";
import {t} from "../../../../locales";
import "./style.scss";

export default function BlockStartGame() {
  return (
    <div className="block-start-game">
      <LandingBackground className="block-start-game__background" />
      <div className="block-start-game__content">
        <h2 className="block-start-game__title">
          {t("Яркие идеи для ваших уроков здесь")}
        </h2>
        <BaseButton className="block-start-game__btn" to={"/games"} large borderless>
          {t("Игры")}
        </BaseButton>
      </div>
    </div>
  );
}
