import React from "react";
import ShortCodeVerification from "../../../ShortCodeVerification";
import { useController } from "./controller";
import { useTranslation } from "../../../../locales";

interface Props {
  email: string;
  onCancel: () => void;
  onCodeVerified: (data: { email: string; token: string }) => void;
}

export default function CodeVerification({ email, onCancel, onCodeVerified }: Props) {
  const { onResendCode, onSendCode } = useController(onCodeVerified);
  const { t } = useTranslation();

  return (
    <ShortCodeVerification
      title={t("Мы отправили вам 6-значный код")}
      email={email}
      onCancel={onCancel}
      resendCode={() => onResendCode(email)}
      sendCode={(code) => onSendCode({ email, code })}
    />
  );
}
