import React from "react";
import GameWithImageSettings, {ImageCard} from "../../../../GameSettings";
import {LessonGameSettings} from "../../../../../types";
import {GameOption} from "../../../../GameSettings/components/GameOptionSelection";

interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

const fieldSizes: GameOption[] = [
  {
    optionValue: 9,
    optionTitle: "3x3",
    optionText: "поле",
    bySubscription: false,
  },
  {
    optionValue: 25,
    optionTitle: "5x5",
    optionText: "поле",
    bySubscription: true,
  },
];

export default function TicTacToeGameSettings({ onGameConfigured }: Props) {

  const handleImageSelection = (images: ImageCard[]) => {
    const game: LessonGameSettings = {
      type: "TIC_TAC_TOE",
      imageCount: images.length,
      images: images,
    }
    onGameConfigured(game);
  }

  return (
    <GameWithImageSettings
      gameOptionsTitle={"Выберите размер поля для игры"}
      gameOptions={fieldSizes}
      handleImageSelection={handleImageSelection}/>
  );
}
