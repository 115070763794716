import './style.scss';
import React from "react";
import {ImageCard} from "../../index";
import BaseIcon from "../../../BaseIcon";

interface Props {
  image: ImageCard;
  handleSelect: (card: ImageCard, selected?: boolean) => void;
  disableSelect: boolean;
}

export default function GameImage({ image, disableSelect = false, handleSelect }: Props) {
  return (
    <div className="game-image" onClick={(e) => e.stopPropagation()}>
      <div onClick={() => !image.selected && disableSelect ? {} : handleSelect(image)}>
        {image.selected ? (
          <div className="game-image-selected">
            <BaseIcon className="game-image-selected __icon">done</BaseIcon>
          </div>
        ) : (<div/>)}
        <img className="game-image__image" src={image.thumbnailSrc} alt={image.name}/>
        <div className="game-image__name">{image.name}</div>
      </div>
    </div>
  )
}