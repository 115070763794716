import './style.scss';
import React from "react";

export interface LottoCardItem {
  id: number;
  src: string;
  shown: boolean;
}

interface Props {
  card: LottoCardItem;
  color: string;
}

export default function LottoCardImage({ card, color}: Props) {
  return (
    <div className="lotto_card_image" style={{borderColor: color, backgroundColor: color}}>
      <div className={!card.shown ? "lotto_card_image__flipped" : ""}>
        <img className="lotto_card_image__front" src={card.src} alt="card front"/>
      </div>
    </div>
  )
}