import React from "react";
import "./style.scss";
import GameWithImageSettings, {ImageCard} from "../../../../GameSettings";
import {GameOption} from "../../../../GameSettings/components/GameOptionSelection";
import {LessonGameSettings} from "../../../../../types";


interface Props {
  onGameConfigured: (game: LessonGameSettings) => void;
}

const imageNumbers: GameOption[] = [
  {
    optionValue: 5,
    bySubscription: false,
  },
  {
    optionValue: 6,
    bySubscription: false,
  },
  {
    optionValue: 8,
    bySubscription: true,
  },
  {
    optionValue: 9,
    bySubscription: true,
  },
  {
    optionValue: 10,
    bySubscription: true,
  },
  {
    optionValue: 12,
    bySubscription: true,
  },
];

export default function MemoryGameSettings({ onGameConfigured }: Props) {
    const handleImageSelection = (images: ImageCard[]) => {
      const game: LessonGameSettings = {
        type: "MEMORY",
        imageCount: images.length,
        images: images,
      }
      onGameConfigured(game);
    }
    return (
      <GameWithImageSettings gameOptions={imageNumbers} handleImageSelection={handleImageSelection}/>
    );
}
